import {Injectable, Type} from '@angular/core';
import {TextInputComponent} from './input-type/text-input/text-input.component';
import {InputComponent} from './input-type/input.component';
import {DialogConfigInputOptionType} from '../../botato-botengine-browser/Core/DialogConfig/DialogConfig';

@Injectable()
export class BotatoChatInputResolverService {

    public inputMappings = new Map<string, Type<InputComponent<DialogConfigInputOptionType>>>();

    constructor() {
        this.inputMappings.set(TextInputComponent.TYPE, TextInputComponent);
    }

    public resolve(inputType: string): Type<InputComponent<DialogConfigInputOptionType>> {
        const inputTypeComponent = this.inputMappings.get(inputType);
        if (inputTypeComponent === undefined) {
            throw new Error(`No InputComponent available for type ${inputType}`);
        }

        return inputTypeComponent;
    }
}
