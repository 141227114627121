// @ts-nocheck comment
import {Input, Directive} from '@angular/core';

@Directive()
export abstract class InputComponent<T> {

    @Input()
    public inputOptions: T;

    @Input()
    public inputComponentFocus: boolean;


}
