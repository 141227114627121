import {Injectable} from '@angular/core';
import {WebSocketMessage} from '../dto/WebSocketMessage';

@Injectable()
export class BotatoChatWebSocketDataInterceptor {

    public transform(type: string, data: WebSocketMessage): WebSocketMessage {
        return data;
    }

}
