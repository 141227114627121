import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class InputDataService {

    public getDataList(dataKey: string): Observable<string[]> {
        throw new Error('Override this method if you would like to provide data for your input elements.');
    }

    public setSearchQuery(dataKey: string, searchQuery: string): void {
        throw new Error('Override this method if you would like to provide data for your input elements.');
    }

}
