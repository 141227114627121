<div class="pa-chat">
    <div *ngIf="isConnected === false" class="pa-connection-problem">{{'NO_CONNECTION_ERROR' | translate}}</div>

    <div class="pa-dialog" #dialog>
        <ng-container *ngFor="let messageGroup of dialogViewModel.messageGroups">

            <div [class.pa-bot]="messageGroup.sender === 'server'"
                 [class.pa-user]="messageGroup.sender === 'client'">
                <div class="pa-avatar"
                     *ngIf="messageGroup.sender === 'server' && messageGroup.botAvatarImageUrl !== undefined"
                     [style.background-image]="'url(' + messageGroup.botAvatarImageUrl + ')'"></div>
                <div class="pa-avatar"
                     *ngIf="messageGroup.sender === 'server' && messageGroup.botAvatarImageUrl === undefined && botAvatar(messageGroup) === true "
                     [style.background-image]="'url(' + dialogViewModel.messageGroups[dialogViewModel.messageGroups.indexOf(messageGroup)-2].botAvatarImageUrl + ')'"></div>
                <div class="pa-avatar"
                     *ngIf="messageGroup.sender === 'server' && messageGroup.botAvatarImageUrl === undefined && botAvatar(messageGroup) === false"></div>
                <div class="pa-avatar" *ngIf="messageGroup.sender === 'client'"></div>
                <div class="pa-messages">
                    <botato-chat-message
                        [class.pa-message]="(message.type !== 'selectionlist') && (message.type !== 'multiselectionlist')"
                        [class.pa-selection-list]="(message.type === 'selectionlist') || (message.type === 'multiselectionlist')"
                        [message]="message"
                        *ngFor="let message of messageGroup.messages"
                    />
                </div>
            </div>

        </ng-container>

        <div class="pa-quickreplies" *ngIf="dialogViewModel.quickReplies !== undefined">
            <button *ngFor="let quickReply of dialogViewModel.quickReplies" type="button" class="pa-quickreply"
                    [ngClass]="quickReply.cssClasses" (click)="selectQuickReply(quickReply)"
                    [innerHTML]="quickReply.displayText | emoji">
            </button>
        </div>

    </div>

    <botato-chat-input
        [type]="inputType"
        [options]="inputOptions"
        [inputComponentFocus]="inputComponentFocus"
    />
</div>
