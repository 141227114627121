import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

// import * as smoothscroll from 'smoothscroll-polyfill';

// smoothscroll.polyfill();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
