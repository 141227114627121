import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTagsByCategory'
})
export class FilterTagsByCategoryPipe implements PipeTransform {

  transform(documents: any[], selectedCategory: string): string[] {
    if (!selectedCategory || selectedCategory === '') {
      return []; // Return an empty array if no category is selected
    }

    // Find the selected category's documents and collect the tags
    const categoryDocuments = documents.find(doc => doc.category === selectedCategory)?.documents || [];

    // Extract tags from the documents within the selected category
    const tags = categoryDocuments.reduce((acc: any, doc:any) => {
      acc.push(...doc.tags); // Collect all tags from the documents
      return acc;
    }, []);

    // Return a unique set of tags
    return Array.from(new Set(tags));
  }
}
