import {Injectable} from '@angular/core';
import {NegativeTextComponent} from './message-components/negative-text/negative-text.component';
import {NoteComponent} from './message-components/note/note.component';
import {LocationComponent} from './message-components/location/location.component';
import {BkpComponent} from './message-components/bkp/bkp.component';
import {BotatoChatMessageResolverService} from '../../libs/botato-angular-lib/botato-chat-message/botato-chat-message-resolver.service';
import {ResponseDtoType} from '../../libs/botato-angular-lib/dto/ResponseDtoType';

@Injectable({
    providedIn: 'root'
})
export class BavoxChatMessageResolverService extends BotatoChatMessageResolverService {

    constructor() {
        super();
        this.messageMapping.set(NegativeTextComponent.TYPE, NegativeTextComponent);
        this.messageMapping.set(NoteComponent.TYPE, NoteComponent);
        this.messageMapping.set(LocationComponent.TYPE, LocationComponent);
        this.messageMapping.set(BkpComponent.TYPE, BkpComponent);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public override resolve(messageType: ResponseDtoType): any {
        return super.resolve(messageType);
    }
}
