// @ts-nocheck comment
import {
    Component, HostListener, Input,
    ViewEncapsulation
} from '@angular/core';
import {VoiceRecognitionService} from './voice-recognition.service';
import {DialogConfigTextInputValidation} from '../../../../../botato-botengine-browser/Core/DialogConfig/DialogConfig';

@Component({
    selector: 'botato-text-voice-input',
    templateUrl: './voice-input.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class VoiceInputComponent {

    @Input() inputTextValidation: DialogConfigTextInputValidation;

    constructor(public voiceService: VoiceRecognitionService) {
    }

    @HostListener('touchstart', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onTouchStart(event: TouchEvent | any): void {
        this.startVoice();
    }

    @HostListener('touchend', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onTouchEnd(event: TouchEvent | any): void {
        this.stopVoice();
    }

    @HostListener('touchcancel', ['$event'])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onTouchCancel(event: TouchEvent | any): void {
        this.stopVoice();
    }


    public startVoice(): void {
        this.voiceService.recordedVoice.next(true);
        this.voiceService.start(this.inputTextValidation);
    }

    public stopVoice(): void {
        this.voiceService.stop();
        this.voiceService.recordedVoice.next(false);
    }
}
