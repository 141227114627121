<div class="chat chat-start !gap-0">
    <div class="chat-image avatar">
        <div class="w-[50px]">
            <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot-thinking.svg" />
        </div>
    </div>
    <div class="chat-bubble !bg-white border-2 border-main-color-400 rounded-md before:!content-none flex items-center min-w-[0px] min-h-[0px] !px-2 !py-0">
        <span class="loading loading-dots text-primary loading-sm !p-0"></span>
    </div>
</div>
