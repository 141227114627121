import {Injectable} from '@angular/core';

@Injectable()
export abstract class BotatoChatStorageService {

    public abstract setItem(key: string, value: string): Promise<void>;

    public abstract getItem(key: string): Promise<string>;

}
