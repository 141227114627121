import { Injectable } from '@angular/core';
import { DatabaseService } from '../databaseService/database.service';
import { TasksService } from 'src/app/tasks/services/tasksService/tasks.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { CoreService } from '../coreServices/core.service';
import { Observable, forkJoin } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  token: string | null = null;

  constructor(
    private coreService: CoreService,
    private authService: AuthService,
    private tasksService: TasksService,
    private db: DatabaseService
  ) {
    this.token = this.authService.getToken();
    // this.setupOnlineEvent();
  }
  
  public setupOnlineEvent() {
    window.addEventListener('online', () => {
      this.synchronizeData();
    });
  };

  appendObjectToFormData(formData: FormData, data: any, parentKey: string = '', excludeKey: string = 'pictures'): void {
    Object.keys(data).forEach(key => {
      if (key === excludeKey) {
        // Skip appending the excluded key
        return;
      }

      const value = data[key];
      const formKey = parentKey ? `${parentKey}[${key}]` : key;

      if (value instanceof Blob) {
        // Directly append Blob or File with the correct key
        formData.append(formKey, value);
      } else if (value instanceof Object && !(value instanceof Date)) {
        // Recursively append nested objects
        this.appendObjectToFormData(formData, value, formKey, excludeKey);
      } else {
        // Append regular values
        formData.append(formKey, value);
      }
    });
  }

  base64ToBlob(base64: string): Blob {
    const byteString = atob(base64.split(',')[1]);
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], {type: mimeString});
  }

  async synchronizeData() {
    if(!this.token) {
      this.token = localStorage.getItem('token');
    }
    const tasks = await this.db.getPendingTasks();
    const deletedTasks = await this.db.getPendingDeletedTasks();
    const pictures = await this.db.getPendingPictures();
    
    const taskObservables = tasks.map(task => {
      const formData = new FormData();

      this.appendObjectToFormData(formData, task.data);
      task.data.pictures.forEach((picture: any) => {
        const blob = this.base64ToBlob(picture);
        formData.append('files', blob);
      });

      return this.createTaskObservable(formData, task);
    });

    const deletedTaskObservables = deletedTasks.map(task =>
      this.deleteTaskObservable(task)
    );

    const pictureObservables = pictures.map(picture => {
      const blob = this.base64ToBlob(picture.data.imageBaseUrl);
      return this.uploadPictureObservable(picture, blob);
    });

    const allObservables = taskObservables.concat(deletedTaskObservables, pictureObservables);

    forkJoin(allObservables).subscribe({
      next: () => {
        this.reloadPageWithAdditionalCalls();
      },
      error: (error) => {
        console.error('Error processing all tasks', error);
      }
    });
  }

  private createTaskObservable(task: any, taskData: any): Observable<any> {
    return new Observable(subscriber => {
      this.tasksService.createTask(this.token!, task).subscribe({
        next: () => {
          this.db.removeTask(taskData.id);
          subscriber.next(null); // Push next and complete
          subscriber.complete();
        },
        error: (error) => {
          console.error('Failed to send post:', taskData.id, error);
          this.db.removeTask(taskData.id);
          subscriber.error(error); // Notify about the error
        }
      });
    });
  }

  private deleteTaskObservable(task: any): Observable<any> {
    return new Observable(subscriber => {
      this.tasksService.deleteTask(this.token!, task.data.id).subscribe({
        next: (res) => {
          if (res.success) {
            this.db.removeDeletedTask(task.id);
            subscriber.next(null); // Push next and complete
            subscriber.complete();
          }
        },
        error: (error) => {
          console.error('Failed to send post:', task.id, error);
          this.db.removeDeletedTask(task.id);
          subscriber.error(error); // Notify about the error
        }
      });
    });
  }

  private uploadPictureObservable(pictureData: any, picture: any): Observable<any> {
    return new Observable(subscriber => {
      this.tasksService.addImage(this.token!, pictureData.data.taskId, picture).subscribe({
        next: (res) => {
          if (res.success) {
            this.db.removePicture(pictureData.id);
            subscriber.next(null); // Push next and complete
            subscriber.complete();
          }
        },
        error: (error) => {
          console.error('Failed to send post:', pictureData.id, error);
          this.db.removePicture(pictureData.id);
          subscriber.error(error); // Notify about the error
        }
      });
    });
  }


  private reloadPageWithAdditionalCalls() {
    if(this.token){
      this.coreService.fullLoad(this.token).subscribe();
      this.tasksService.filterInformation(this.token).subscribe();
      this.tasksService.listEntities(this.token).subscribe();
    }
    window.location.reload();
  }

}
