// @ts-nocheck comment
import {ApplicationRef, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DialogViewModelCreatorService} from '../../../../service/dialog-view-model-creator.service';
import {CommunicationService} from '../../../../service/communication.service';
import {TranslateService} from '@ngx-translate/core';
import {BotatoSpeechRecognition, BotatoSpeechRecognitionEvent} from './speech-recognition-types';
import {DialogConfigTextInputValidation} from '../../../../../botato-botengine-browser/Core/DialogConfig/DialogConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let webkitSpeechRecognition: any;

@Injectable({
    providedIn: 'root'
})
export class VoiceRecognitionService {

    public recordedVoice = new BehaviorSubject<boolean>(false);
    public recognition: BotatoSpeechRecognition;
    public tempWords = '';
    public recognizing = false;
    public finalTranscript = '';
    public recognitionStopped = false;

    private validation: DialogConfigTextInputValidation;

    constructor(private applicationRef: ApplicationRef,
                public dialogViewModelCreator: DialogViewModelCreatorService,
                private communicationService: CommunicationService,
                private translationService: TranslateService) {
    }

    public init(android: boolean): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.recognition = new webkitSpeechRecognition() as BotatoSpeechRecognition;
        this.recognition.interimResults = true;
        this.recognition.lang = this.translationService.currentLang;
        this.recognition.continuous = !android;

        this.recognition.addEventListener('start', () => {
            this.recognizing = true;
            // console.log('Speech Recognition started');
        });

        this.recognition.addEventListener('end', () => {
            // console.log('Speech Recognition end');
            this.recognizing = false;
            if (this.recognitionStopped) {
                if (this.finalTranscript.length > 1) {
                    if (this.validation !== undefined) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (new RegExp(this.validation.regex).test(this.finalTranscript)) {
                            this.sendMessage();
                        } else {
                            this.tempWords = this.finalTranscript;
                            this.finalTranscript = '';
                        }
                    } else {
                        this.sendMessage();
                    }
                }
            } else {
                this.recognition.start();
            }
        });

        this.recognition.addEventListener('result', (event: BotatoSpeechRecognitionEvent) => {
            this.tempWords = this.finalTranscript;
            if (typeof (event.results) === 'undefined') {
                // eslint-disable-next-line no-null/no-null
                this.recognition.onend = null;
                this.recognition.stop();
                return;
            }
            for (let i = event.resultIndex; i < event.results.length; ++i) {
                if (event.results[i].isFinal) {
                    this.finalTranscript += event.results[i][0].transcript;
                } else {
                    this.tempWords += event.results[i][0].transcript;
                }
                this.applicationRef.tick();
            }
        });

        this.recognition.addEventListener('error', (error) => {
            this.recognition.stop();
            this.tempWords = '';
            // console.log('Speech Recognition error', error);
        });
    }

    public start(inputTextValidation: DialogConfigTextInputValidation): void {
        if (inputTextValidation !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            this.validation = inputTextValidation;
        }
        if (this.recognizing) {
            this.recognition.stop();
            return;
        }
        this.recognitionStopped = false;
        this.recognition.start();
    }

    public stop(): void {
        this.recognitionStopped = true;
        this.recognition.stop();
        // console.log('Speech Recognition stopped');
    }

    private sendMessage(): void {
        this.dialogViewModelCreator.addUserTextMessage(this.finalTranscript);
        this.communicationService.sendMessage(this.finalTranscript, 'text');
        this.applicationRef.tick();
        this.finalTranscript = '';
    }
}
