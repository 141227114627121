import {Component, Input} from '@angular/core';
import {MessageViewModel} from '../../../../libs/botato-angular-lib/viewmodel/MessageViewModel';
import {MessageType} from '../../../../libs/botato-angular-lib/botato-chat-message/message-type/message-type';

@Component({
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss']
})
export class LocationComponent implements MessageType {
    public static TYPE = 'LocationItem';

    @Input()
    public message!: MessageViewModel;
}
