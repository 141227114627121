import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreService {
  private envUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  fullLoad(token: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/projects/full-load`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      },
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }
}
