// @ts-nocheck comment

import {Component, OnInit, Output, EventEmitter, HostListener, ViewChild, ElementRef} from '@angular/core';
import {Subject, Observable, fromEvent, switchMap, takeUntil, pairwise, retry, filter} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { icons } from 'src/assets/images/icons';
import { Location } from '@angular/common';
import { TasksService } from 'src/app/tasks/services/tasksService/tasks.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from 'src/app/core/services/imageService/image.service';
import { DatabaseService } from 'src/app/core/services/databaseService/database.service';
import { NetworkStatusService } from 'src/app/core/services/networkStatusService/network-status.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})


export class CameraComponent implements OnInit {
  @ViewChild('canvas', {static: true}) myCanvas!: ElementRef;
  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();

  public networkOnline: boolean | undefined;

  public exitIcon: string = icons.exitIcon;
  public imageIcon: string = icons.imageIcon;
  public switchCamera: string = icons.switchCameraIcon;
  public undoIcon: string = icons.undoIcon;
  public redoIcon: string = icons.redoIcon;
  public checkIcon: string = icons.checkIconNoCircle;

  private token: string | null = localStorage.getItem('token');

  public backButtonPath: string[];
  private projectId: string;
  private journalId: string;
  private mode: string;
  private noteId: string;
  private journalView: string

  // toggle webcam on/off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public facingMode: string = 'environment';
  public deviceId: string;
  public pictureUrl: string;
  public modPictureUrl: string;
  public errors: WebcamInitError[] = [];
  public deviceHeight: number = 500
  public screenWidth: number = 0
  public screenHeight: number = 0

  private steps: number;
  private currentStep: number;
  private isDrawing: boolean;
  private drawnImages: {step: number, currentPosY: number, currentPosX: number, prevPosX: number, prevPosY: number, brushSize: number, brushColor: string}[] = []
  private filteredDrawnImages: {step: number, currentPosY: number, currentPosX: number, prevPosX: number, prevPosY: number, brushSize: number, brushColor: string}[] = []

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  private takingPicture: boolean = true
  public editingPicture: boolean = false

  private cx: CanvasRenderingContext2D;

  private brushSize: number;
  public brushColor: string;
  public redActive: boolean = false
  public whiteActive: boolean = false
  public blackActive: boolean = true
  public yellowActive: boolean = false
  public greenActive: boolean = false
  public smallBrushActive: boolean = false
  public mediumBrushActive: boolean = true
  public largeBrushActive: boolean = false

  private  updateAlert: string = "Updated successfully";

  @HostListener('window:resize', ['$event'])
  onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    this.screenWidth = win.innerWidth;
    this.screenHeight = win.innerHeight;
  }

  public previousUrl: string

  constructor(
    private networkStatusService: NetworkStatusService,
    private dbService: DatabaseService,
    private imageService: ImageService,
    private route: ActivatedRoute,
    public router: Router,
    private location: Location,
    private toastr: ToastrService,
    private translate: TranslateService,
    private tasksService: TasksService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.onResize();
    const prevNav = this.router.getCurrentNavigation().previousNavigation;
    if(prevNav?.finalUrl) {
      this.previousUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  }

  public ngOnInit(): void {
    this.networkStatusService.connected$.subscribe(status => {
      this.networkOnline = status;
    })
    this.projectId = this.route.snapshot.params['projectId'] as string
    this.mode = this.route.snapshot.params['mode'] as string
    this.journalId = this.route.snapshot.params['journalId'] as string
    this.noteId = this.route.snapshot.params['noteId'] as string
    this.journalView = this.route.snapshot.params['view'] as string

    this.backButtonPath = ['/chat', this.projectId, this.mode, this.journalId]
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });

    this.translate
      .get([
        'alert.update'
      ])
      .subscribe((translations: { [key: string]: string }) => {
        this.updateAlert = translations['alert.update'];
      });
  }

  public async canvasInit(): Promise<void>{
    const canvasEl: HTMLCanvasElement = this.myCanvas.nativeElement as HTMLCanvasElement
    this.cx = canvasEl.getContext('2d');
    this.brushColor= '#000';
    this.brushSize = 5;
    this.steps = -1
    this.drawBackgroundPicture()
    this.captureEvents(canvasEl);
    //this.cx.lineCap = 'round';
  }

  public waitForImageToLoad(imageElement: HTMLImageElement){
    return new Promise(resolve=>{imageElement.onload = resolve})
  }

  public drawBackgroundPicture(){
    const myImage = new Image()
    myImage.src = this.pictureUrl
    const canvasEl: HTMLCanvasElement = this.myCanvas.nativeElement as HTMLCanvasElement
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.waitForImageToLoad(myImage).then(() => {
      const imgWidth = myImage.width;
      const imgHeight = myImage.height;
      canvasEl.width = imgWidth;
      canvasEl.height = imgHeight;
      canvasEl.getContext('2d').drawImage(myImage, 0, 0, imgWidth, imgHeight)
      this.filteredDrawnImages.map(draw => {
        this.cx.strokeStyle = draw.brushColor
        this.cx.lineWidth = draw.brushSize
        this.drawOnCanvas({x: draw.prevPosX, y: draw.prevPosY},{x: draw.currentPosX, y:draw.currentPosY})
      })
    })
  }

  public getImageDimensions(file: string) {
    return new Promise (function (resolved, rejected) {
      const i = new Image()
      i.onload = function(){
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {
    fromEvent(canvasEl, 'touchstart')
    .pipe(
      switchMap((e) => {
        this.steps < this.currentStep?this.steps = this.currentStep:undefined
        this.steps++
        this.drawnImages = this.filteredDrawnImages
        this.isDrawing = true
        this.cx.lineWidth = this.brushSize
        this.cx.strokeStyle = this.brushColor
        // after a mouse down, we'll record all mouse moves
        e.preventDefault()
        return fromEvent(canvasEl, 'touchmove')
          .pipe(
            takeUntil(fromEvent(canvasEl, 'touchend')),
            pairwise()
          )
      })
    )
    .subscribe((res: [TouchEvent, TouchEvent]) => {
      const rect = canvasEl.getBoundingClientRect();
      // previous and current position with the offset
      const prevPos = {
        x: res[0].changedTouches[0].clientX - rect.left,
        y: res[0].changedTouches[0].clientY - rect.top
      };
      const currentPos = {
        x: res[1].changedTouches[0].clientX - rect.left,
        y: res[1].changedTouches[0].clientY - rect.top
      };
      this.pushDrawing(currentPos.x, currentPos.y, prevPos.x, prevPos.y)
      this.drawOnCanvas(prevPos, currentPos);
    });

    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
    .pipe(
      switchMap((e) => {
        this.steps < this.currentStep?this.steps = this.currentStep:undefined
        this.steps++
        this.drawnImages = this.filteredDrawnImages
        this.isDrawing = true
        this.cx.lineWidth = this.brushSize
        this.cx.strokeStyle = this.brushColor
        // after a mouse down, we'll record all mouse moves
        return fromEvent(canvasEl, 'mousemove')
          .pipe(
            // we'll stop (and unsubscribe) once the user releases the mouse
            // this will trigger a 'mouseup' event
            takeUntil(fromEvent(canvasEl, 'mouseup')),
            // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
            takeUntil(fromEvent(canvasEl, 'mouseleave')),
            // pairwise lets us get the previous value to draw a line from
            // the previous point to the current point
            pairwise()
          )
      })
    )
    .subscribe((res: [MouseEvent, MouseEvent]) => {
      const rect = canvasEl.getBoundingClientRect();
      // previous and current position with the offset
      const prevPos = {
        x: res[0].clientX - rect.left,
        y: res[0].clientY - rect.top
      };
      const currentPos = {
        x: res[1].clientX - rect.left,
        y: res[1].clientY - rect.top
      };
      this.pushDrawing(currentPos.x, currentPos.y, prevPos.x, prevPos.y)
      this.drawOnCanvas(prevPos, currentPos);
    });

    fromEvent(canvasEl, 'touchend').subscribe(()=>{
      this.currentStep = this.steps
    })
    fromEvent(canvasEl, 'mouseup').subscribe(()=>{
      this.currentStep = this.steps
    })
    fromEvent(canvasEl, 'mouseleave').subscribe(()=>{
      this.currentStep = this.steps
    })
  }

  public pushDrawing(currentPosX: number, currentPosY: number, prevPosX: number, prevPosY: number){
    this.drawnImages.push({
      step: this.steps,
      currentPosY: currentPosY,
      currentPosX: currentPosX,
      prevPosX: prevPosX,
      prevPosY: prevPosY,
      brushSize: this.brushSize,
      brushColor: this.brushColor
    })
  }

  private drawOnCanvas(prevPos: { x: number, y: number }, currentPos: { x: number, y: number }) {
    if (!this.cx) { return; }
    this.cx.beginPath();

    if (prevPos) {
      this.cx.moveTo(prevPos.x, prevPos.y); // from
      this.cx.lineTo(currentPos.x, currentPos.y);
      this.cx.stroke();
    }
  }

  public async undoDrawing(){
    if (this.currentStep > -1){
      this.isDrawing = false
      this.currentStep--
      this.filteredDrawnImages = this.drawnImages.filter(x => x.step <= this.currentStep)
      //this.cx.clearRect(0,0,this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height)
      //await this.canvasInit()
      this.drawBackgroundPicture()
    }
  }

  public async redoDrawing(){
    if(!this.isDrawing && this.currentStep < this.steps){
      this.currentStep++
      this.filteredDrawnImages = this.drawnImages.filter(x => x.step <= this.currentStep)
      //this.cx.clearRect(0,0,this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height)
      //await this.canvasInit()
      this.drawBackgroundPicture()
    }
  }

  // fix this
  public async saveCanva(): Promise<void> {
    //this.drawBackgroundPicture(true)
    if (this.journalView === 'new-task') {
      this.imageService.storeImage(this.canvasToJPEGFile())
      .then(() => {
        this.goBackToPrevPage();
      });
    } else {
      if (!this.networkOnline) {
        this.dbService.uploadPicture(this.canvasToJPEGFile())
        .then(() => {
          this.toastr.success(this.updateAlert, '', {
            positionClass: 'toast-top-center',
          });
          if (this.journalView === 'chat') {
            this.goBackToPrevPage();
          } else {
            this.onSaveNavigateTo(); 
          }
        });
      } else {
        this.tasksService.addImage(this.token, this.noteId, this.canvasToJPEGFile()).subscribe(data => {
          if(data.success){
            this.toastr.success(this.updateAlert, '', {
              positionClass: 'toast-top-center',
            });
            if (this.journalView === 'chat') {
              this.goBackToPrevPage();
            } else {
              this.onSaveNavigateTo(); 
            }
          }
        });

      }
    }
  }

  private canvasToJPEGFile() {
    const base64Data = this.myCanvas.nativeElement.toDataURL('image/jpeg');
    const blob = this.base64ToBlob(base64Data);
    const imageFile = new File([blob], 'canvas_image.jpeg', { type: 'image/jpeg' });
    return imageFile;
  }

  private base64ToBlob(base64: string, type: string = 'image/jpeg'): Blob {
    const binaryString = window.atob(base64.split(',')[1]);
    const len = binaryString.length;
    const binaryArray = new Uint8Array(len);
    
    for (let i = 0; i < len; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }
  
    return new Blob([binaryArray], {type: type});
  }

  // ************************************************

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    //console.info('received webcam image', webcamImage);
    this.pictureUrl = webcamImage.imageAsDataUrl
    this.takingPicture = false
    this.editingPicture = true
    this.canvasInit()
    this.pictureTaken.emit(webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    //console.log(`active device: ${  deviceId}`);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public async save(blob: Blob): Promise<void>{
    this.modPictureUrl = await this.blobToBase64(blob)
    this.editingPicture = false
  }

  public async blobToBase64(blob: Blob): Promise<string>{
    return blob
  }

  public isTakingPicture(){
    return this.takingPicture
  }

  public isEditingPicture(){
    return this.editingPicture
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = { ideal: this.facingMode };
    }
    return result;
  }

  public changeBrushSize(lineWidth: number): void{
    //console.log('change brush size')
    this.brushSize = lineWidth;
    this.smallBrushActive = false
    this.mediumBrushActive = false
    this.largeBrushActive = false
    switch(lineWidth){
      case 1:
        this.smallBrushActive = true
        break
      case 5:
        this.mediumBrushActive = true
        break
      case 10:
        this.largeBrushActive = true
        break
      default:
        return
    }
  }

  public changeBrushColor(color: string): void{
    //console.log('change brush color')
    this.brushColor = color;
    this.redActive = false
    this.greenActive = false
    this.whiteActive = false
    this.blackActive = false
    this.yellowActive = false

    switch(color){
      case '#ffffff':
        this.whiteActive = true
        break
      case '#000000':
        this.blackActive = true
        break
      case '#ff0000':
        this.redActive = true
        break
      case '#008000':
        this.greenActive = true
        break
      case '#ffff00':
        this.yellowActive = true
        break
      default:
        return
    }
  }

  goBackToPrevPage() {
    this.location.back();
  }
  
  onSaveNavigateTo() {
    if(this.previousUrl.includes('photo-gallery')){
      this.router.navigate([this.previousUrl]);
    } else {
      this.router.navigate([this.previousUrl, 'photo-gallery'])
    }
  }

}
