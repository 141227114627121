import {DeviceInfoDto} from './DeviceInfoDto';
import {WebSocketMessage} from './WebSocketMessage';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';


export class WebSocketClientRequestDto extends WebSocketMessage{
    constructor(
        public message: string,
        deviceInfo: DeviceInfoDto,
        language: string,
        clientId: string,
        botId: string,
        inputSource?: InputSourceType,
        authToken?: string
    ) {
        super(language, clientId, botId, {deviceInfo: deviceInfo}, inputSource, authToken);
    }
}
