 // @ts-nocheck comment
import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {ContenteditableHelper} from './ContenteditableHelper';
import {InputSelection} from './InputSelection';

/**
 * This directive solves the following problems for all browsers
 * - Consistent two-way binding
 * - Max length limitation (typing and copy/paste)
 * - Cleaning of HTML polluted text while inserting
 *
 * A contenteditable is not yet supported by angular. With the [textContent]='value' and (input)='...'
 * does not work in IE and Firefox, as after every keystroke the cursor is set to position 0
 *
 * This solution we have from Stackoverflow:
 * https://stackoverflow.com/questions/35378087/how-to-use-ngmodel-on-divs-contenteditable-in-angular2/41253897#41253897
 */
@Directive({selector: '[contenteditableModel]'})
export class ContenteditableModelDirective implements OnChanges {

    @Input()
    public contenteditableModel: string;

    @Input()
    public maxLength: number;


    @Output()
    public contenteditableModelChange = new EventEmitter();

    private lastViewModel: string;

    constructor(private elementRef: ElementRef) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['contenteditableModel'] && changes['contenteditableModel'].currentValue !== this.lastViewModel) {
            this.lastViewModel = this.contenteditableModel;
            this.refreshView();
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        if (changes['contenteditableModel'] && changes['contenteditableModel'].currentValue?.trim().length === 0) {
            // Firefox does create a "<br>"-Tag when removing all characters in a contenteditable
            // To get the placeholder working, we manually clear the innerHTML of the contentEditable
            // ElementRef data is any
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            this.elementRef.nativeElement.innerHTML = '';
        }
    }

    @HostListener('keydown', ['$event'])
    public onKeydown(event: KeyboardEvent): void {
        if (this.hasMaxInputLength() && !ContenteditableHelper.isAllowedKeyCode(event) && !this.hasSelection() && this.getText().length >= this.maxLength) {
            event.preventDefault();
        }
    }

    @HostListener('keyup')
    public onKeyup(): void {
        this.bindInput();
    }

    @HostListener('input')
    public onInput(): void {
        // Only for iOS autocomplete: To set the model and shorten the text if necessary
        this.bindInput();
    }

    @HostListener('paste', ['$event'])
    public onPaste(event: Event): void {
        const selection = this.getSelection();
        event.preventDefault();

        let start: string, end;
        let maxInsertLength: number;

        const isContenteditableEmpty = selection === undefined;
        if (isContenteditableEmpty) {
            // Insert at begining
            start = '';
            maxInsertLength = this.maxLength;
            end = '';
        } else {
            start = this.getText().substr(0, selection.start);
            maxInsertLength = this.maxLength - this.getText().length + selection.end - selection.start;
            end = this.getText().substr(selection.end);
        }

        const insertText = (!this.hasMaxInputLength()) ? ContenteditableHelper.getClipboardContent(event) : ContenteditableHelper.getClipboardContent(event).substr(0, maxInsertLength);

        this.setText(start + insertText + end);
        this.bindInput();

        if (ContenteditableHelper.isInternetExplorer(event)) {
            // Internet Explorer setzt den Cursor ans Ende des Inputs, wenn der Cursor ohne "setTimeout" gesetzt wird
            setTimeout(() => {
                this.setCursor(start.length + insertText.length);
            });
        } else {
            this.setCursor(start.length + insertText.length);
        }
    }

    private bindInput(): void {
        if (this.lastViewModel !== this.getText()) {
            // iOS: Autocomplete does not call onKeydown (and not insert prevention happens). For this reason we have to shorten the text after the autocomplete insert.
            if (this.hasMaxInputLength() && this.getText().length > this.maxLength) {
                this.setText(this.getText().substr(0, this.maxLength));
                this.setCursor(this.maxLength);
            }
            this.lastViewModel = this.getText();
            this.contenteditableModelChange.emit(this.getText());
        }
    }

    private refreshView(): void {
        this.setText(this.contenteditableModel);
    }

    private hasSelection(): boolean {
        const selection = this.getSelection();
        return selection !== undefined && selection.start !== selection.end;
    }

    private getSelection(): InputSelection {
        let selection: Selection, range: Range;
        if (window.getSelection) {
            selection = window.getSelection();
            if (selection.rangeCount) {
                range = selection.getRangeAt(0);
                if (range.commonAncestorContainer.parentNode === this.elementRef.nativeElement) {
                    return new InputSelection(range.startOffset, range.endOffset);
                }
            }
        }
        return undefined;
    }

    private setCursor(position: number): void {
        const range = document.createRange();
        const sel = window.getSelection();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        range.setStart(this.elementRef.nativeElement.childNodes[0], position);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        // ElementRef data is any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        this.elementRef.nativeElement.focus();
    }

    private getText(): string {
        // ElementRef data is any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        return this.elementRef.nativeElement.innerText;
    }

    private setText(text: string): void {
        // ElementRef data is any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        this.elementRef.nativeElement.innerText = text;
    }

    private hasMaxInputLength(): boolean {
        return this.maxLength !== undefined && this.maxLength !== 0;
    }
}
