// @ts-nocheck comment
import {ApplicationRef, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommunicationService} from '../../../service/communication.service';
import {DialogViewModelCreatorService} from '../../../service/dialog-view-model-creator.service';
import {BotatoChatManagerService} from '../../../service/botato-chat-manager.service';
import {InputComponent} from '../input.component';
import {VoiceRecognitionService} from './voice/voice-recognition.service';
import {Subscription} from 'rxjs';
import {DialogConfigInputOptionsTextInput, DialogConfigInputType} from '../../../../botato-botengine-browser/Core/DialogConfig/DialogConfig';

@Component({
    templateUrl: './text-input.component.html',
    encapsulation: ViewEncapsulation.None,
})

export class TextInputComponent extends InputComponent<DialogConfigInputOptionsTextInput> implements OnInit, OnDestroy {

    public isConnected: boolean;
    public inputFocus: boolean;
    public static TYPE: DialogConfigInputType = 'text';
    public userText: string = '';
    public addVoiceInput: boolean = false;
    public validateVoiceTextError: boolean = false;

    @ViewChild('messageInput', {static: true})
    private messageInput: ElementRef;
    private subscription = new Subscription();

    constructor(private communicationService: CommunicationService,
                public dialogViewModelCreator: DialogViewModelCreatorService,
                private chatManagerService: BotatoChatManagerService,
                private applicationRef: ApplicationRef,
                public voiceService: VoiceRecognitionService) {
        super();

    }

    public ngOnInit(): void {
        if (this.inputComponentFocus) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            this.messageInput.nativeElement.focus();
        }
        this.setDefaultText();
        this.checkVoiceAvailability();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        this.subscription.add(this.chatManagerService.focusInputEvent.subscribe(() => this.messageInput.nativeElement.focus()));
        this.subscription.add(this.communicationService.connectionStateChanged.subscribe((state: boolean) => this.isConnected = state));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onEnterPressed(event: KeyboardEvent): void {
        if (event.keyCode === 13 || event.key === 'Enter') {
            event.preventDefault();

            // It can happen that the keyDown Event of the Enter-key does happen before the keyUp-event of the last
            // key the user typed before hitting the enter button. In this case the binding did not yet refresh
            // the userText and we send an incomplete message. To fix this we add additional 100ms to make sure
            // the event of the last keystroke happened.
            setTimeout(() => {
                if (this.canSendMessage()) {
                    this.sendUserText();
                }
            }, 100);
        }
        this.applicationRef.tick();
    }

    public onKeyUp(): void {
        this.applicationRef.tick();
    }

    public sendUserText(): void {
        this.sendMessage(this.userText);
        this.userText = '';
        this.applicationRef.tick();
    }

    public canSendMessage(): boolean {
        // return this.userText !== undefined && this.userText.trim().length !== 0 && this.isConnected !== false && this.validateText(this.userText);
        // eslint-disable-next-line
        return this.userText !== undefined && this.userText.trim().length !== 0 && this.isConnected !== false && this.validateText(this.userText) && !this.validateVoiceText();
    }

    public onFocus(): void {
        this.inputFocus = true;
    }

    public validateVoiceText(): boolean {
        if (this.voiceService.finalTranscript.length > 0 && !this.validateText(this.voiceService.finalTranscript)) {
            this.voiceService.tempWords = this.voiceService.finalTranscript;
            this.validateVoiceTextError = true;
            return true;
        }
        return false;
    }

    public validateText(text: string): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const regularExpression = new RegExp(this.inputOptions?.validation?.regex);
        return regularExpression.test(text);
    }

    private sendMessage(text: string): void {
        this.dialogViewModelCreator.addUserTextMessage(text);
        this.communicationService.sendMessage(text, 'text');
    }

    private setDefaultText(): void {
        if (this.inputOptions.defaultText) {
            this.userText = this.inputOptions.defaultText;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            this.messageInput.nativeElement.focus();
        }
    }

    private checkVoiceAvailability(): void {
        if (this.inputOptions.enableVoice) {
            if ('webkitSpeechRecognition' in window) {
                this.addVoiceInput = true;
                // console.log('webkitSpeechRecognition exist');
                this.voiceService.init(/Android/i.test(navigator.userAgent));
            } else {
                this.addVoiceInput = false;
                // console.log('webkitSpeechRecognition not exist');
            }
        }
    }
}
