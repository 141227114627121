// @ts-nocheck comment
import {
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {BotatoChatInputResolverService} from './botato-chat-input-resolver.service';
import {BotatoChatInputDirective} from './botato-chat-input.directive';
import {InputComponent} from './input-type/input.component';
import {DialogConfigInputOptionsTextInput, DialogConfigInputOptionType} from '../../botato-botengine-browser/Core/DialogConfig/DialogConfig';

@Component({
    selector: 'botato-chat-input',
    templateUrl: './botato-chat-input.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BotatoChatInputComponent implements OnChanges {

    private currentInputType: string;
    private currentComponentInstance: ComponentRef<InputComponent<DialogConfigInputOptionType>>;

    @ViewChild(BotatoChatInputDirective, {static: true})
    public inputHost: BotatoChatInputDirective;

    @Input()
    public type: string;

    @Input()
    public inputComponentFocus: boolean;

    @Input()
    public options: DialogConfigInputOptionType;

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
                private chatInputComponentTypeResolver: BotatoChatInputResolverService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.type) {
            const newInputType: string = changes.type.currentValue as string;

            if (newInputType !== this.currentInputType) {
                this.inputHost.viewContainerRef.remove();

                if (newInputType !== 'none') {
                    this.createInputHost(newInputType);

                    this.currentComponentInstance.instance.inputOptions = this.options;
                    this.currentComponentInstance.instance.inputComponentFocus = this.inputComponentFocus;
                }

                this.currentInputType = newInputType;
            }
        }

        if (changes.options && this.currentComponentInstance !== undefined) {
            if (this.currentInputType === 'text') {
                this.checkTextInputOptionChanges(changes.options.currentValue as DialogConfigInputOptionsTextInput);
            }
            this.currentComponentInstance.instance.inputOptions = this.options;
            this.currentComponentInstance.instance.inputComponentFocus = this.inputComponentFocus;
        }
    }

    // link with a problem
    private checkTextInputOptionChanges(options: DialogConfigInputOptionsTextInput): void {
        const currentInputOptions = this.currentComponentInstance.instance.inputOptions as DialogConfigInputOptionsTextInput;
        if (options.enableVoice !== currentInputOptions.enableVoice) {
            this.inputHost.viewContainerRef.remove();
            this.createInputHost(this.currentInputType);
        }
        if (options.defaultText !== currentInputOptions.defaultText) {
            this.inputHost.viewContainerRef.remove();
            this.createInputHost(this.currentInputType);
        }
    }

    private createInputHost(inputType: string): void {
        const componentType = this.chatInputComponentTypeResolver.resolve(inputType);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        this.currentComponentInstance = this.inputHost.viewContainerRef.createComponent(componentFactory);
    }
}
