import {FactoryProvider, ModuleWithProviders, NgModule, Type} from '@angular/core';
import {BotatoChatStorageService} from './service/botato-chat-storage.service';
import {BotatoChatManagerService} from './service/botato-chat-manager.service';
import {DialogViewModelCreatorService} from './service/dialog-view-model-creator.service';
import {CommunicationService} from './service/communication.service';
import {
    BotatoChatWebSocketCommunicationService
} from './service/botato-chat-web-socket-communication.service';
import {BotatoAngularLibBaseModule} from './botato-angular-lib-base.module';
import {BotatoChatComponent} from './botato-chat/botato-chat.component';
import {BotatoChatMessageResolverService} from './botato-chat-message/botato-chat-message-resolver.service';
import {BotatoConfig} from './botato.config';
import {BotatoChatInputResolverService} from './botato-chat-input/botato-chat-input-resolver.service';
import {InputDataService} from './botato-chat-input/input-data.service';
import {BotatoChatWebSocketDataInterceptor} from './service/botato-chat-web-socket-data-interceptor.service';


@NgModule({
    imports: [
        BotatoAngularLibBaseModule
    ],
    exports: [BotatoChatComponent]

})
export class BotatoAngularLibModule {

    public static with(storageService: Type<BotatoChatStorageService>,
                       config: () => BotatoConfig,
                       inputDataService: Type<InputDataService> = InputDataService,
                       chatMessageResolverService: Type<BotatoChatMessageResolverService> = BotatoChatMessageResolverService,
                       chatInputResolverService: Type<BotatoChatInputResolverService> = BotatoChatInputResolverService,
                       chatWebsocketDataInterceptor: Type<BotatoChatWebSocketDataInterceptor> = BotatoChatWebSocketDataInterceptor): ModuleWithProviders<BotatoAngularLibModule> {
        return {
            ngModule: BotatoAngularLibModule,
            providers: [
                BotatoChatManagerService,
                DialogViewModelCreatorService,
                {provide: BotatoChatWebSocketDataInterceptor, useClass: chatWebsocketDataInterceptor},
                {provide: BotatoChatInputResolverService, useClass: chatInputResolverService},
                {provide: BotatoChatMessageResolverService, useClass: chatMessageResolverService},
                {provide: CommunicationService, useClass: BotatoChatWebSocketCommunicationService},
                {provide: BotatoChatStorageService, useClass: storageService},
                {provide: 'BOTATO_CONFIG', useFactory: config} as FactoryProvider,
                {provide: InputDataService, useClass: inputDataService}
            ]
        };
    }
}
