import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';

import {BotatoConfig} from './libs/botato-angular-lib/botato.config';
import {botatoBaseConfig} from './libs/botato-angular-lib/botato-base.config';
import {BotatoAngularLibModule} from './libs/botato-angular-lib/botato-angular-lib.module';
import {InputDataService} from './libs/botato-angular-lib/botato-chat-input/input-data.service';
import { BavoxChatMessageResolverService } from './feature/chatbot/bavoxChatMessageResolver.service';
import { BavoxChatInputResolverService } from './feature/chatbot/bavoxChatInputResolverService';
import { BavoxWebsocketInterceptor } from './feature/chatbot/bavoxWebsocketInterceptor';
import { LocalStorageService } from './shared/services/local-storage.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { BotComponent } from './feature/chatbot/bot/bot.component';
import { ViewSwitchComponent } from './feature/chatbot/view-switch/view-switch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import {NegativeTextComponent} from './feature/chatbot/message-components/negative-text/negative-text.component';
import {NoteComponent} from './feature/chatbot/message-components/note/note.component';
import {LocationComponent} from './feature/chatbot/message-components/location/location.component';
import {BkpComponent} from './feature/chatbot/message-components/bkp/bkp.component';
import {BavoxVoiceInputComponent} from './feature/chatbot/voice-input-component/bavox-voice-input.component';
import {ContenteditableModelDirective} from './feature/chatbot/voice-input-component/contenteditable-model.directive';
import { CameraComponent } from './feature/camera/camera.component';
import { WebcamModule } from 'ngx-webcam';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { AbilityModule, AbilityService } from '@casl/angular';
import { createAbility, AppAbility } from './shared/services/AppAbility';
import { MultiTranslateHttpLoader } from './core/loaders/multi-translate-http-loader';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DataService } from './core/services/dataService/data.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';

export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "../assets/i18n/", suffix: ".json" },
    { prefix: "./assets/i18n/auth/", suffix: ".json" },
    { prefix: "./assets/i18n/dashboard/", suffix: ".json" },
    { prefix: "./assets/i18n/organization/", suffix: ".json" },
    { prefix: "./assets/i18n/profile/", suffix: ".json" },
    { prefix: "./assets/i18n/projects/", suffix: ".json" },
    { prefix: "./assets/i18n/tasks/", suffix: ".json" }
  ]);
}

export function botatoConfigCreator(): BotatoConfig {
  const botatoConfig: BotatoConfig = botatoBaseConfig as BotatoConfig;
  botatoConfig.socketPath = environment.websocketpath;
  botatoConfig.socketUrl = environment.websocket;
  botatoConfig.defaultLanguage = 'de';
  return botatoConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    BotComponent,
    ViewSwitchComponent,
    NegativeTextComponent,
    NoteComponent,
    LocationComponent,
    BkpComponent,
    BavoxVoiceInputComponent,
    ContenteditableModelDirective,
    CameraComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    BotatoAngularLibModule.with(
      LocalStorageService,
      botatoConfigCreator,
      InputDataService,
      BavoxChatMessageResolverService,
      BavoxChatInputResolverService,
      BavoxWebsocketInterceptor
    ),
    AppRoutingModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
      }
    }),
    WebcamModule,
    AbilityModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    PdfViewerModule
  ],
  providers: [
    CookieService,
    { provide: AppAbility, useFactory: createAbility },
    AbilityService,
    DataService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
