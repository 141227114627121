<div class="chat chat-start !gap-0">
    <div class="chat-image avatar">
      <div class="w-[50px]">
        <!-- <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot.svg" /> -->
      </div>
    </div>
    <div class="flex items-end gap-1">
      <div class="chat-bubble !min-h-0 !bg-[#EDF3E6] rounded-md before:!content-none">
        <h1 class="text-[#81BE3F] font-medium text-base text-left">
            BKP definiert
        </h1>
        <p>{{message.data.bkp.number}} {{message.data.bkp.description}}</p>
      </div>
      <div class="chat-footer opacity-50">
        {{message.date | date: "HH:mm"}}
      </div>
    </div>
  </div>
