<div class="bav-main-container">

	<div class="grid grid-cols-12 gap-2 px-4 pt-7 pb-6 border-b-2 border-white-shades-400">
		<div class="col-span-2 sm:col-span-2 md:col-span-4 flex items-center justify-start">
			<button (click)="goToTaskPage()" class="btn btn-circle btn-link btn-sm">
				<app-svg-icon [src]="chevronLeft" height="32px" width="32px"/>
			</button>
		</div>
		<div class="col-span-6 sm:col-span-6 md:col-span-4 flex items-center justify-center">
			<h1 class="text-secondary-color-500 font-semibold text-2xl truncate">{{journal ? journal.name : ''}}</h1>
		</div>
		<div class="col-span-4 sm:col-span-4 md:col-span-4 flex items-center justify-end">
			<div class="flex items-center gap-4">
				<button [routerLink]="['/profile', 'settings', 'help-center', 'chatbot']" class="btn btn-circle btn-link btn-xs">
					<app-svg-icon
						[src]="exclamationMarkCircle"
						width="24px"
						height="24px"
						stroke="black"
						strokeWidth="2px"
					/>
				</button>
				<button (click)="toggleModal()" class="btn btn-circle btn-link btn-xs">
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M2.25 12.75V12C2.25 11.4033 2.48705 10.831 2.90901 10.409C3.33097 9.98705 3.90326 9.75 4.5 9.75H19.5C20.0967 9.75 20.669 9.98705 21.091 10.409C21.5129 10.831 21.75 11.4033 21.75 12V12.75M13.06 6.31L10.94 4.19C10.8007 4.05055 10.6353 3.93992 10.4533 3.86442C10.2712 3.78892 10.0761 3.75004 9.879 3.75H4.5C3.90326 3.75 3.33097 3.98705 2.90901 4.40901C2.48705 4.83097 2.25 5.40326 2.25 6V18C2.25 18.5967 2.48705 19.169 2.90901 19.591C3.33097 20.0129 3.90326 20.25 4.5 20.25H19.5C20.0967 20.25 20.669 20.0129 21.091 19.591C21.5129 19.169 21.75 18.5967 21.75 18V9C21.75 8.40326 21.5129 7.83097 21.091 7.40901C20.669 6.98705 20.0967 6.75 19.5 6.75H14.121C13.7233 6.74965 13.341 6.59138 13.06 6.31Z" stroke="#001A30" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>					
				</button>
				<div class="drawer w-min">
						<input id="my-drawer" type="checkbox" class="drawer-toggle" />
						<div class="flex items-center">
								<label for="my-drawer" class="btn btn-circle btn-link btn-sm">
										<app-svg-icon [src]="menu" height="32px" width="32px"/>
								</label>
						</div>
						<!-- -left-0 -->
						<div class="drawer-side z-30 lg:max-w-[1008px] md:max-w-screen-md xs:max-w-screen-xs right-0 -left-0 mx-auto">
								<label for="my-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
								<ul class="menu w-80 p-0 min-h-full bg-secondary-color-500 text-base-content">
										<div class="py-5 px-4 border-b-[1px] border-white-shades-400 flex justify-between items-center">
												<label for="my-drawer" class="btn btn-circle btn-link btn-xs">
														<app-svg-icon
																[src]="exitIcon"
																width="24px"
																height="24px"
																stroke="white"
																strokeWidth="2px"
														/>
												</label>
												<h1 class="text-white font-semibold text-xl">{{journal ? journal.name : ''}}</h1>
										</div>
										<div class="p-4 space-y-4">
												<div [routerLink]="['/projects', projectId, 'journal']" class="text-white font-bold text-base hover:bg-white hover:bg-opacity-20 p-3 rounded-xl cursor-pointer flex justify-between items-center">
														Baujournale
														<app-svg-icon
																[src]="chevronRight"
																width="24px"
																height="24px"
																stroke="white"
																strokeWidth="2px"
														/>
												</div>
												<div [routerLink]="['/projects', projectId, 'objectAcceptance']" class="text-white font-bold text-base hover:bg-white hover:bg-opacity-20 p-3 rounded-xl cursor-pointer flex justify-between items-center">
														Zustandserfassung
														<app-svg-icon
																[src]="chevronRight"
																width="24px"
																height="24px"
																stroke="white"
																strokeWidth="2px"
														/>
												</div>
												<div [routerLink]="['/projects', projectId, 'creationAcceptance']" class="text-white font-bold text-base hover:bg-white hover:bg-opacity-20 p-3 rounded-xl cursor-pointer flex justify-between items-centeraaaaaa">
														Werkabnahme
														<app-svg-icon
																[src]="chevronRight"
																width="24px"
																height="24px"
																stroke="white"
																strokeWidth="2px"
														/>
												</div>  
										</div>
										<div class="p-4 w-full sticky bottom-0 flex-1 flex items-end">
												<div [routerLink]="['/profile', 'settings', 'help-center', 'chatbot']" class="text-white font-bold text-base hover:bg-white hover:bg-opacity-20 p-3 rounded-xl cursor-pointer flex items-center w-full gap-4">
														<app-svg-icon
																[src]="exclamationMarkCircle"
																width="24px"
																height="24px"
																stroke="white"
																strokeWidth="2px"
														/>
														Hilfezentrum
												</div>
										</div>
								</ul>
						</div>
				</div>
			</div>
		</div>
	</div>

	<div class="bav-page-content">
		<div class="di-bot">
			<div class="di-bot-info-bar" *ngIf="location || bkp">
				<div class="di-bot-info-bar-text">
						{{formatInfoBarText()}}
				</div>
			</div>
			<div class="dm-bot-container">
				<botato-chat [initialInputType]="'none'"></botato-chat>
			</div>
		</div>
	</div>

</div>


<!-- datenraum -->

<input type="checkbox" id="my_modal_2" class="modal-toggle" [checked]="showModal"/>

<dialog id="my_modal_2" class="modal modal-bottom">
  <div class="modal-box flex flex-col h-screen md:max-w-screen-md xs:max-w-screen-xs mx-auto !p-0 shadow-[0px_-3px_51px_-16px_rgba(0,0,0,0.15)]">
    <div class="relative pt-4 pb-3 border-b-[1px] border-white-shades-400">
      <h3 class="font-bold text-lg text-center">{{"Datenraum" | translate}}</h3>
      <form method="dialog">
        <button (click)="toggleModal()" class="btn btn-circle btn-link btn-xs absolute right-[16px] top-[16px]">
          <app-svg-icon
            [src]="exitIcon"
            width="24px"
            height="24px"
          />
        </button>
      </form>
    </div>
    <div class="flex-1 flex flex-col p-4 overflow-y-auto">
			<div class="flex justify-between gap-5">
				<div class="input-wrapper">
					<div class="input-icon left">
						<app-svg-icon
							[src]="searchIcon"
							[width]="'24px'"
							[height]="'24px'"
							[stroke]="'#C6C6C6'"
						/>
					</div>
					<input
						type="text"
						[(ngModel)]="searchDocument"
						placeholder="{{ 'shared-links.search-shared-link' | translate }}"
						class="max-w input-icon-right input-icon-left"
					/>
					<div class="dropdown absolute flex items-center dropdown-bottom dropdown-end input-icon right">
						<div tabindex="0" class="btn btn-circle btn-link btn-xs">
							<app-svg-icon
								[src]="searchFilter"
								[width]="'24px'"
								[height]="'24px'"
								[stroke]="'black'"
								[fill]="'black'"
							/>
						</div>
						<ul tabindex="0" class="dropdown-content z-[1] mt-3 -mr-3 !p-0 menu border-[1px] border-white-shades-400 bg-white rounded-box w-[264px] md:w-[350px] lg:w-[450px]">
							<div class="p-3 border-b-[1px] border-white-shades-400 flex justify-between items-center">
								<h3 class="font-normal text-sm text-main-color-500">{{'kanban-general.filter-tasks' | translate}}</h3>
								<button (click)="clearAllFilters()" class="btn btn-link btn-link-primary btn-xs">{{"kanban-general.clear-all" | translate}}</button>
							</div>
							<div class="p-3">
								<div class="input-wrapper">
									<div class="input-icon left">
										<app-svg-icon [src]="searchIcon" [width]="'24px'" [height]="'24px'" [stroke]="'#C6C6C6'"></app-svg-icon>
									</div>
									<input type="text" placeholder="{{'kanban-general.filter' | translate}} Category, Tags" class="max-w input-icon-left !h-[40px]" [(ngModel)]="searchFilterString"/>
								</div>
		
								<div class="max-h-[calc(40dvh)] lg:max-h-[calc(50dvh)] overflow-x-auto">
									<div class="badge gap-2 my-2 !p-0 h-fit bg-transparent border-transparent">
										<app-svg-icon
											[src]="searchFilter"
											fill="black"
										/>
										{{"kanban-general.filter" | translate}}
									</div>
				
									<ul class="menu p-0 space-y-2">
										<li *ngIf="(listDocumentCategories | filter: searchFilterString).length > 0">
											<span #DocumentCategory class="menu-dropdown-toggle menu-dropdown-show bg-[#F5F4F4] font-medium text-sm text-secondary-color-500" (click)="toggleFilterDropdown(DocumentCategory, subDocumentCategory)">{{"Category" | translate}}</span>
											<ul #subDocumentCategory class="menu-dropdown menu-dropdown-show before:content-none ms-0 py-2 px-4">
												<div class="space-y-3 mb-3 max-h-32 overflow-y-auto">
													<ng-container *ngFor="let item of listDocumentCategories | filter: searchFilterString">
														<div *ngIf="!item.canBeDeleted && !item.isExpired" class="form-control">
															<label class="label cursor-pointer justify-start gap-2 !p-0">
																<input
																	type="checkbox"
																	name="filter_document_category"
																	class="checkbox checkbox-primary"
																	[checked]="checkedFilter('filter_document_category', item.name)"
																	(change)="onCheckboxChange(item.name, $event)"
																/>
																<span class="label-text whitespace-pre-wrap break-all">{{item.name}}</span> 
															</label>
														</div>
													</ng-container>
												</div>
											</ul>
										</li>
										<li *ngIf="(filteredListOfTags | filter: searchFilterString).length > 0">
											<span #Tags class="menu-dropdown-toggle bg-[#F5F4F4] font-medium text-sm text-secondary-color-500" (click)="toggleFilterDropdown(Tags, subTags)">{{"Tags" | translate}}</span>
											<ul #subTags class="menu-dropdown before:content-none ms-0 py-2 px-4">
												<div class="space-y-3 mb-3 max-h-32 overflow-y-auto">
													<ng-container *ngFor="let item of filteredListOfTags | filter: searchFilterString">
														<div *ngIf="!item.canBeDeleted && !item.isExpired" class="form-control">
															<label class="label cursor-pointer justify-start gap-2 !p-0">
																<input
																	type="checkbox"
																	name="filter_document_tags"
																	class="checkbox checkbox-primary"
																	[checked]="checkedFilter('filter_document_tags', item.name)"
																	(change)="onCheckboxChange(item.name, $event)"
																/>
																<span class="label-text whitespace-pre-wrap break-all">{{item.name}}</span> 
															</label>
														</div>
													</ng-container>
												</div>
											</ul>
										</li>
									</ul>
		
								</div>
								
							</div>
						</ul>
					</div>
				</div>
			</div>

      <div class="flex-1 flex flex-col overflow-auto">
				<div class="flex-1 flex flex-col gap-5 my-5 overflow-y-auto">
					<ng-container *ngIf="(dropdown | filterBy : selectedFilterCategory: 'category').length > 0">
						<div
							*ngFor="let item of dropdown |
								filterBy : selectedFilterCategory: 'category';
								let i = index
							"
						>
							<div
								*ngIf="
									(item.documents | filterBy : selectedFilterTags: 'tags').length > 0 &&
									(item.documents | filter : searchDocument: ['name']).length > 0
								"
								class="collapse bg-white-shades-200 !p-0"
								[ngClass]="{
									'collapse-open overflow-visible': dropdownOpen[i]
								}"
							>
								<div
									(click)="toggleDropdown(i)"
									class="flex items-center justify-between !py-2 !px-4 h-min text-secondary-color-500 text-lg font-semibold cursor-pointer"
								>
									<h1>{{item.category}}</h1>
									<div
										class="transition-all btn btn-circle btn-link btn-sm"
										[ngClass]="{
											'-rotate-180': dropdownOpen[i],
											'rotate-0': !dropdownOpen[i]
										}"
									>
										<app-svg-icon [src]="chevronUp" width="24px" height="24px" stroke="#001A30" />
									</div>
								</div>
								<div class="collapse-content space-y-5">
									<div
										*ngFor="let subItem of item.documents |
											filter: searchDocument : ['name'] |
											filterBy : selectedFilterTags: 'tags';
											let i = index
										"
										class="collapse overflow-visible bg-white !rounded-xl border-[1px] !p-0"
										[ngClass]="{
											'collapse-open': subDropdownOpen[subItem.id],
											'border-white': isDateValid(subItem.expirationDate),
											'border-error-color-500': !isDateValid(subItem.expirationDate)
										}"
									>
										<div class="flex flex-col items-start justify-between !py-4 !px-4 gap-3 h-min text-secondary-color-500 text-lg font-semibold">
											<div *ngIf="subItem.tags.length > 0" class="flex flex-wrap items-center gap-2">
												<span *ngFor="let tag of subItem.tags" class="w-fit flex items-center justify-center gap-2 bg-[#D3EEFC] !border-none rounded-full !py-[2px] !px-2">
													<span class="w-2 h-2 bg-primary rounded-full"></span>
													<span class="text-primary font-medium text-xs">{{tag}}</span>
												</span>
											</div>
											
											<div class="w-full flex justify-between items-center gap-3">
												<button class="flex items-center gap-3 overflow-hidden" (click)="setDocumentUrl(subItem.url, subItem.key)">
													<p class="text-secondary-color-500 font-semibold text-left line-clamp-1">
														{{subItem.name}}
													</p>
													<div>
														<app-svg-icon [src]="eyeIcon" width="24px" height="24px" stroke="#22a8f0" />
													</div>
												</button>
		
												<div class="flex items-center justify-between gap-3">
													<button
														(click)="toggleSubDropdown(subItem.id)"
														class="transition-all btn btn-circle btn-link btn-sm -mt-1"
														[ngClass]="{
															'-rotate-180': subDropdownOpen[subItem.id],
															'rotate-0': !subDropdownOpen[subItem.id]
														}"
													>
														<app-svg-icon [src]="chevronUp" width="24px" height="24px" stroke="#001A30" />
													</button>
												</div>
											</div>
										</div>
										<div class="collapse-content">
											<div class="pt-2 pb-4">
												<p class="text-sm font-normal text-secondary-color-500 break-all">
													{{subItem.description}}
												</p>
											</div>
											<p class="text-secondary-color-500 font-semibold flex justify-between">
												<span>Expiration date</span>
												<span>{{(subItem.expirationDate | date: "dd/MM/yyyy") ?? '--'}}</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
  </div>
  <form (click)="toggleModal()" method="dialog" class="modal-backdrop">
  </form>
</dialog>

<!-- show document modal -->
<input type="checkbox" id="pdfDocumentModal" class="modal-toggle" [checked]="showDocumentModal" />
<div class="modal" role="dialog">
  <div class="modal-box relative !max-w-screen-md h-full !pt-[40px]">
		<button
			(click)="closeDocumentModal()"
			class="btn btn-circle btn-link btn-primary btn-xs absolute right-2 top-2"
		>
			<app-svg-icon
				[src]="exitIcon"
				[stroke]="'#001A30'"
				[height]="'24px'"
				[width]="'24px'"
			/>
		</button>

		<div *ngIf="selectedDocumentType !== 'pdf'" class="w-full h-full flex items-center justify-center">
			<img
				[src]="selectedDocumentUrl" alt="document"
				class="w-full max-h-full object-contain"
			>
		</div>

    <pdf-viewer
			*ngIf="selectedDocumentType === 'pdf'"
			[src]="selectedDocumentUrl"
			style="display: block;"
			class="w-full h-full"
      
			[show-borders]="false"
			[render-text]="true"
			[render-text-mode]="1"

			[original-size]="false"
			zoom-scale="page-fit"
			[zoom]="1"
    ></pdf-viewer>
  </div>
  <label class="modal-backdrop" (click)="closeDocumentModal()"></label>
</div>
