// @ts-nocheck comment
import {ResponseDtoType} from '../dto/ResponseDtoType';
import {QuickReplyDto} from '../dto/QuickReplyDto';
import {SenderType} from './MessageGroupViewModel';

export class MessageViewModel {
    public date: Date = new Date();
    public type: ResponseDtoType;
    public sender: SenderType;
    public quickReplies: QuickReplyDto[];
    public botAvatarImageUrl?: string;
    // Data can be any
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public data: any;
}
