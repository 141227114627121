export class ContenteditableHelper {
    public static isAllowedKeyCode(event: KeyboardEvent): boolean {
        return (event.keyCode === 8 || event.key === 'Backspace') ||
            (event.keyCode === 9 || event.key === 'Tab') ||
            (event.keyCode === 33 || event.key === 'PageUp') ||
            (event.keyCode === 34 || event.key === 'PageDown') ||
            (event.keyCode === 35 || event.key === 'End') ||
            (event.keyCode === 36 || event.key === 'Home') ||
            (event.keyCode === 37 || event.key === 'ArrowLeft') ||
            (event.keyCode === 38 || event.key === 'ArrowUp') ||
            (event.keyCode === 39 || event.key === 'ArrowRight') ||
            (event.keyCode === 40 || event.key === 'ArrowDown') ||
            (event.keyCode === 46 || event.key === 'Delete') ||
            event.ctrlKey ||
            event.metaKey;
    }

    // eslint-disable-next-line
    public static isInternetExplorer(event: any): boolean {
        // eslint-disable-next-line
        return event.clipboardData === undefined;
    }

    // eslint-disable-next-line
    public static getClipboardContent(event: any): string {
        if (ContenteditableHelper.isInternetExplorer(event)) {
            // eslint-disable-next-line
            return (window as ClipboardWindow).clipboardData.getData('Text');
        } else {
            // eslint-disable-next-line
            return event.clipboardData.getData('text/plain');
        }
    }
}

export type ClipboardWindow = (typeof window) & {
    // eslint-disable-next-line
    clipboardData: any;
};
