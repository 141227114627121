// @ts-nocheck comment
import {VoiceRecognitionService} from './voice-recognition.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import Recorder from './Recorder';
import {GoogleApiClient} from './google-api.client';
import {DialogConfigTextInputValidation} from '../../../../libs/botato-botengine-browser/Core/DialogConfig/DialogConfig';

export class GoogleCloudVoiceRecognitionService implements VoiceRecognitionService {
    private liveTranscript: Subject<string> = new BehaviorSubject<string>('');
    private isRecording: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private finalText: Subject<string> = new Subject();

    public finalText$: Observable<string> = this.finalText.asObservable();
    public isRecording$: Observable<boolean> = this.isRecording.asObservable();
    public liveTranscript$: Observable<string> = this.liveTranscript.asObservable();

    private recorder: Recorder;
    private currentMediaStream: MediaStream;

    private isStopCalled: boolean = false;

    constructor() {
        GoogleApiClient.load();
    }

    public start(inputTextValidation?: DialogConfigTextInputValidation): void {
        this.isStopCalled = false;

        navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(stream => {

            if (this.isStopCalled) {
                // This happens when the user for the first time clicks on the record button
                // and the browser requests for permission. In this case stop was already
                // called and we do not want to start the recording then.
                return;
            }

            this.currentMediaStream = stream;
            this.recorder = new Recorder(new AudioContext().createMediaStreamSource(stream));
            this.recorder.record();
            this.isRecording.next(true);
        }).catch(() => {
            this.isRecording.next(false);
        });
    }

    public stop(): void {
        this.isStopCalled = true;
        this.isRecording.next(false);

        if (this.recorder !== undefined) {
            this.recorder.stop();
            this.currentMediaStream.getAudioTracks()[0].stop();

            this.recorder.exportWAV(async (blob: Blob) => {
                await this.exportWavData(blob);
            });
        }

    }

    private async exportWavData(blob: Blob) {
        const base64AudioData = await this.convertToBase64(blob);
        const recognizedText = await GoogleApiClient.recognizeSpeech(base64AudioData);

        if (recognizedText !== undefined) {
            this.finalText.next(recognizedText);
        }
    }

    private convertToBase64(blob: Blob): Promise<string> {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => resolve((reader.result as string).split(',')[1]);
        });
    }
}
