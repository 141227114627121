import {Component, Input, OnInit} from '@angular/core';
import {DeletedMessagesHolder} from './deletedMessagesHolder';
import {MessageType} from '../../../../libs/botato-angular-lib/botato-chat-message/message-type/message-type';
import {MessageViewModel} from '../../../../libs/botato-angular-lib/viewmodel/MessageViewModel';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { icons } from 'src/assets/images/icons';
import { TasksService } from 'src/app/tasks/services/tasksService/tasks.service';
import { Location } from '@angular/common';
import { NavigationService } from 'src/app/shared/services/navigationService/navigation.service';
import { filter } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './note.component.html',
    styleUrls: ['./note.component.scss']
})
export class NoteComponent implements MessageType, OnInit {
    public trashIcon: string = icons.trashCanIcon;
    public cameraIcon: string = icons.cameraIcon;
    public showMoreIcon: string = icons.verticalShowMoreIcon;
    public imageIcon: string = icons.imageIcon;
    public exitIcon: string = icons.exitIcon;
    public calendarIcon: string = icons.calendarIcon;

    private updateAlert: string = "Updated successfully";
    private errorAlert: string = "Something went wrong";

    public isLoading: boolean = false;
    public isAddDueDateModalOpen:boolean = false;

    private token: string | null = null;
    public taskId: string | null = null;
    public projectId: string | null = null;
    public journalType: string | null = null;
    public journalId: string | null = null;

    public static TYPE = 'NoteItem';

    @Input()
    public message!: MessageViewModel;

    public dueDateTask = new FormGroup({
        deadlineDate: new FormControl(new Date().toISOString())
      });

    constructor(
        private authService: AuthService,
        private api: TasksService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private navigationService: NavigationService,
        public router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit() {
        this.token = this.authService.getToken();
        this.route.params.subscribe(params => {
            const {projectId, mode, journalId} = params;
            this.projectId = projectId;
            this.journalType = mode;
            this.journalId = journalId;
        })

        this.translate
        .get([
          'alert.update',
          'alert.error'
        ])
        .subscribe((translations: { [key: string]: string }) => {
          this.updateAlert = translations['alert.update'];
          this.errorAlert = translations['alert.error'];
        });
    }

    public isDeleted(): boolean {
        return DeletedMessagesHolder.isDeleted(this.getMessageId());
    }

    public async addPicture(): Promise<void> {
        await this.router.navigate([
            'camera',
            this.route.snapshot.params['projectId'],
            this.route.snapshot.params['mode'],
            this.route.snapshot.params['journalId'],
            'chat',
            'note',
            this.getMessageId(),
        ]);
    }

    public async deleteNote(): Promise<void> {
        const token = localStorage.getItem('token');
        DeletedMessagesHolder.delete(this.getMessageId());
        this.api.deleteTask(token, this.getMessageId()).subscribe();
    }

    private getMessageId(): string {        
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        return this.message.data.note.id;
    }

    public navigateToGallery(taskId:string){
        const navigateTo = `/tasks/${this.projectId}/${this.journalId}/${this.journalType}/task-details/${taskId}/photo-gallery`;
        this.navigationService.setOriginForPage('originUrl', this.router.url);
        this.router.navigate([navigateTo])
    }

    onDueDateSubmit(){
        const body = {
            deadlineDate: new Date(this.dueDateTask.controls['deadlineDate'].value!).toISOString()
        };

        if(this.dueDateTask.valid){
            this.isLoading = true;
            this.api.updateTask(this.token, this.taskId, body).subscribe({
            next: (res) => {
                if (res) {
                    this.isLoading = false;
                    this.toastr.success(this.updateAlert, '', {
                        positionClass: 'toast-top-center',
                    });
                    this.isAddDueDateModalOpen = false;
                }
            },
            error: (err) => {
                if (err) {
                    this.isLoading = false;
                    this.toastr.error(this.errorAlert, '', {
                        positionClass: 'toast-top-center',
                    });
                    this.isAddDueDateModalOpen = false;
                }
            }
            })
        }
    }

    toggleAddDueModal(taskId: string | null){
        this.isAddDueDateModalOpen = !this.isAddDueDateModalOpen;
        this.taskId = taskId;
    }
}
