<div class="form-control input-wrapper" [ngClass]="class">
  <label *ngIf="label" class="label pl-4 py-[2px]">
    <span class="label-text text-white-shades-400">{{ label }}</span>
  </label>
  <button
    type="button"
    [ngClass]="{'text-secondary-color-500': control.value, 'text-white-shades-400': placeholder && !control.value}"
    class="relative z-10 justify-start items-center select select-bordered rounded-full text-base font-medium focus:border-secondary-color-500"
    (click)="onInputFocus()"
    (blur)="onBlur()"
  >
    <input
      #dateInput
      min="{{formattedDate}}"
      class="opacity-0 pointer-events-none absolute z-10 col-span-5"
      [formControl]="control"
      type="date"
    />
    <span *ngIf="placeholder && !control.value ">{{placeholder}}</span>
    <span *ngIf="control.value?.length !== 0">{{ control.value | date: 'dd.MM.yyyy' }}</span>
  </button>
  <p
    class="input-error-message"
    *ngIf="requiredText && (control.dirty || control.touched) && control.errors?.['required']"
  >
    {{ requiredText }}
  </p>
</div>
