import {ApplicationRef, EventEmitter, Injectable} from '@angular/core';
import {EventItemDto} from '../dto/EventItemDto';
import {DialogViewModelCreatorService} from './dialog-view-model-creator.service';
import {Subject} from 'rxjs';
import {ResponseDto} from '../dto/ResponseDto';
import {CommunicationService} from './communication.service';
import {TranslateService} from '@ngx-translate/core';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';

@Injectable()
export class BotatoChatManagerService {

    private eventClickSubject: Subject<EventItemDto> = new Subject();
    private scrollToBottomSubject: Subject<void> = new Subject();

    constructor(private communicationService: CommunicationService,
                private dialogViewModelCreator: DialogViewModelCreatorService,
                private translationService: TranslateService,
                private applicationRef: ApplicationRef) {
    }

    public focusInputEvent: EventEmitter<void> = new EventEmitter<void>();

    public start(): void {
        this.communicationService.welcome();
    }

    public focusInput(): void {
        this.focusInputEvent.emit();
    }

    public sendUserMessage(message: string, inputSource: InputSourceType): void {
        this.dialogViewModelCreator.addUserTextMessage(message);
        this.communicationService.sendMessage(message, inputSource);
    }

    public scrollToBottom(): void {
        this.scrollToBottomSubject.next();
    }

    public readMessages(): EventEmitter<ResponseDto> {
        return this.communicationService.messageReceived;
    }

    public getEventClickSubject(): Subject<EventItemDto> {
        return this.eventClickSubject;
    }

    public getScrollToBottomSubject(): Subject<void> {
        return this.scrollToBottomSubject;
    }

    public setLanguage(language: string): void {
        if (!this.translationService.getLangs().some(availableLanguage => availableLanguage === language)) {
            throw new Error(`Tried to set a not available language ${language} - available languages: ${this.translationService.getLangs()}`);
        }

        this.translationService.use(language);
        this.applicationRef.tick();
    }

    public clearDialog(): void {
        this.dialogViewModelCreator.clearDialog();
    }

    public setAuthToken(authToken: string): void {
        this.communicationService.setAuthToken(authToken);
    }

}
