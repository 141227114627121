import { AbilityBuilder, AbilityClass, createMongoAbility, MongoAbility, PureAbility } from '@casl/ability';

type Actions = 'create' | 'read' | 'update' | 'delete';
type Subjects = 'Organization' | 'Tasks' | 'Chat' | 'Projects' | 'Navigation';

export type AppAbility = MongoAbility<[Actions, Subjects]>;
export const AppAbility = PureAbility as AbilityClass<AppAbility>;

export function defineAbilitiesFor(role: string) {
  const { can, rules, cannot } = new AbilityBuilder(AppAbility);

  if (role === 'user' || role === 'orgUser') {
    //create
    cannot('create', 'Organization');
    can('create', 'Tasks');
    //delete
    cannot('delete', 'Organization');
    can('delete', 'Tasks');
    //update
    cannot('update', 'Organization');
    can('update', 'Tasks');
    //read
    cannot('read', 'Organization');
    can('read', 'Tasks');
    can('read', 'Navigation');

  }

  if (role === 'admin') {
    //create
    can('create', 'Organization');
    can('create', 'Tasks');
    //delete
    can('delete', 'Organization');
    can('delete', 'Tasks');
    //update
    can('update', 'Organization');
    can('update', 'Tasks');
    //read
    can('read', 'Organization');
    can('read', 'Tasks');
    can('read', 'Navigation');

  }

  if (role === 'orgAdmin') {
    //create
    can('create', 'Organization');
    can('create', 'Tasks');
    //delete
    can('delete', 'Organization');
    can('delete', 'Tasks');
    //update
    can('update', 'Organization');
    can('update', 'Tasks');
    //read
    can('read', 'Organization');
    can('read', 'Tasks');
    can('read', 'Navigation');

  }

  if(role === undefined || role === null) {
    //create
    cannot('create', 'Organization');
    cannot('create', 'Tasks');
    //delete
    cannot('delete', 'Organization');
    cannot('delete', 'Tasks');
    //update
    cannot('update', 'Organization');
    cannot('update', 'Tasks');
    //read
    can('read', 'Organization');
    can('read', 'Tasks');
    cannot('read', 'Navigation');
  }


  return rules;
}

export function createAbility(expires:any) {
  return createMongoAbility(defineAbilitiesFor(expires), {
    detectSubjectType: object => object['kind'],
  });
}