// @ts-nocheck comment
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MessageType} from '../message-type';
import {ResponseDtoType} from '../../../dto/ResponseDtoType';
import {MessageViewModel} from '../../../viewmodel/MessageViewModel';

@Component({
    templateUrl: './typing-indicator.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TypingIndicatorComponent implements MessageType {

    public static TYPE: ResponseDtoType = 'typingindicator';

    @Input()
    public message: MessageViewModel;
}
