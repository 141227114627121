import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], searchText: string|null, properties: string[] = []): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    
    // Check if items array contains strings and handle accordingly
    if (items.length > 0 && typeof items[0] === 'string') {
      return items.filter(item => this.includesSearchText(item, searchText!));
    }

    // For items array of objects
    return items.filter(item => this.searchNested(item, properties, searchText!));
  }

  private searchNested(item: any, properties: string[], searchText: string): boolean {
    if (!item || properties.length === 0) {
      return false;
    }

    for (const property of properties) {
      const keys = property.split('.');
      const value = this.getPropertyValue(item, keys);
      
      if (Array.isArray(value)) {
        for (let element of value) {
          if (this.includesSearchText(element, searchText)) {
            return true;
          }
        }
      } else if (this.isSearchable(value) && this.includesSearchText(value, searchText)) {
        return true;
      }
    }

    return false;
  }

  private getPropertyValue(item: any, keys: string[]): any {
    let value = item;
    for (const key of keys) {
      if (value.hasOwnProperty(key)) {
        value = value[key];
      } else {
        return undefined;
      }
    }
    return value;
  }

  private isSearchable(value: any): boolean {
    return typeof value === 'string' || typeof value === 'number';
  }

  private includesSearchText(value: any, searchText: string): boolean {
    if (this.isValidDate(searchText) || this.isPartialDate(searchText)) {
      return this.filterByDate(value, searchText);
    }
    if (typeof value === 'string') {
      return value.toLowerCase().includes(searchText);
    }
    if (typeof value === 'number') {
      return value.toString().includes(searchText);
    }
    return false;
  }

  private isValidDate(dateString: string): boolean {
    const dateRegex1 = /^([1-9]|0[1-9]|[12][0-9]|3[01])[\/\.\-]([1-9]|0[1-9]|1[0-2])[\/\.\-](\d{4})$/;
    const dateRegex2 = /^(\d{4})[\/\.\-]([1-9]|0[1-9]|1[0-2])[\/\.\-]([1-9]|0[1-9]|[12][0-9]|3[01])$/;
    return dateRegex1.test(dateString) || dateRegex2.test(dateString);
  }

  private isPartialDate(dateString: string): boolean {
    const partialDateRegex1 = /^([1-9]|0[1-9]|[12][0-9]|3[01])[\/\.\-]([1-9]|0[1-9]|1[0-2])$/;
    const partialDateRegex2 = /^(\d{4})[\/\.\-]([1-9]|0[1-9]|1[0-2])$/;
    const partialDateRegex3 = /^(\d{4})$/;
    return partialDateRegex1.test(dateString) || partialDateRegex2.test(dateString) || partialDateRegex3.test(dateString);
  }

  private filterByDate(item: string, userDate: string): boolean {
    if (!this.isSearchable(item)) {
      return false;
    }

    const itemDate = new Date(item);

    if (isNaN(itemDate.getTime())) {
      return false;
    }

    if (this.isValidDate(userDate)) {
      let [year, month, day] = userDate.includes('.') ? userDate.split('.') : userDate.includes('/') ? userDate.split('/') : userDate.split('-');
      if (year.length !== 4) {
        [day, month, year] = [year, month, day];
      }
      // Add leading zeros if necessary
      month = month.length === 1 ? '0' + month : month;
      day = day.length === 1 ? '0' + day : day;
      const formattedUserDate = new Date(`${year}-${month}-${day}`);
      formattedUserDate.setUTCHours(0, 0, 0, 0);

      return itemDate.toISOString() === formattedUserDate.toISOString();
    }

    if (this.isPartialDate(userDate)) {
      const partialDateParts = userDate.split(/[\/\.\-]/);
      const itemDateParts = itemDate.toISOString().split('T')[0].split('-'); // YYYY-MM-DD

      if (partialDateParts.length === 2) {
        // Match month and year or day and month
        return (partialDateParts[0].length === 4 && itemDateParts[0] === partialDateParts[0] && itemDateParts[1] === (partialDateParts[1].length === 1 ? '0' + partialDateParts[1] : partialDateParts[1])) || // YYYY-MM
               (partialDateParts[0].length !== 4 && itemDateParts[2] === (partialDateParts[0].length === 1 ? '0' + partialDateParts[0] : partialDateParts[0]) && itemDateParts[1] === (partialDateParts[1].length === 1 ? '0' + partialDateParts[1] : partialDateParts[1]));   // DD-MM
      }

      if (partialDateParts.length === 1 && partialDateParts[0].length === 4) {
        // Match year only
        return itemDateParts[0] === partialDateParts[0];
      }
    }

    return false;
  }
}
