// @ts-nocheck comment
import {Translation} from './translation';

export interface BotatoBaseConfig {
    translations: Translation[];
    defaultLanguage: string;
}

export const botatoBaseConfig: BotatoBaseConfig = {
    translations: [
        {
            language: 'de',
            translations: {
                'CHARACTER_INDICATOR': '{{length}} / {{maxLength}} Zeichen',
                'NO_CONNECTION_ERROR': 'Verbindungsproblem: Versuche Verbindung zum Server aufzubauen...',
                'ITEM_LIST_CHOOSE': 'Auswählen',
                'PLACEHOLDER': 'Nachricht eingeben...',
                'FILE_UPLOAD': {
                    'TITLE_ERROR_MULTIPLE': 'Dateien konnten nicht geladen werden:',
                    'TITLE_ERROR_SINGLE': 'Datei konnte nicht geladen werden:',
                    'FILE_ERROR_SIZE': 'Datei zu gross',
                    'FILE_ERROR_TYPE': 'Falsches Dateiformat',
                    'FILE_ERROR_UNKNOWN': 'Unbekannter Fehler'
                },
                'AUTOCOMPLETE': {
                    'HINT_NO_ENTRIES': 'Keine Suchresultate'
                }
            }
        },
        {
            language: 'en',
            translations: {
                'CHARACTER_INDICATOR': '{{length}} / {{maxLength}} Signs',
                'NO_CONNECTION_ERROR': 'Connection problem: Trying to connect to the server...',
                'ITEM_LIST_CHOOSE': 'Select',
                'PLACEHOLDER': 'Enter message...',
                'FILE_UPLOAD': {
                    'TITLE_ERROR_MULTIPLE': 'Files could not be loaded:',
                    'TITLE_ERROR_SINGLE': 'File could not be loaded:',
                    'FILE_ERROR_SIZE': 'File too big',
                    'FILE_ERROR_TYPE': 'Incorrect file format',
                    'FILE_ERROR_UNKNOWN': 'Unknown error'
                },
                'AUTOCOMPLETE': {
                    'HINT_NO_ENTRIES': 'No search results'
                }
            }
        },
        {
            language: 'fr',
            translations: {
                'CHARACTER_INDICATOR': '{{length}} / {{maxLength}} Signe',
                'NO_CONNECTION_ERROR': 'Problèmes de connexion : tentatives de connexion au serveur...',
                'ITEM_LIST_CHOOSE': 'Choisir',
                'PLACEHOLDER': 'Veuillez entrer le texte...',
                'FILE_UPLOAD': {
                    'TITLE_ERROR_MULTIPLE': 'Les fichiers n\'ont pas pu être chargés :',
                    'TITLE_ERROR_SINGLE': 'Le fichier n\'a pas pu être chargé :',
                    'FILE_ERROR_SIZE': 'Fichier trop gros',
                    'FILE_ERROR_TYPE': 'Format de fichier incorrect',
                    'FILE_ERROR_UNKNOWN': 'Erreur inconnue'
                },
                'AUTOCOMPLETE': {
                    'HINT_NO_ENTRIES': 'Aucun résultat'
                }
            }
        }],

    defaultLanguage: undefined
};
