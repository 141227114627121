import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/services/auth.service';

import { AppAbility, defineAbilitiesFor } from 'src/app/shared/services/AppAbility';
import { Platform } from '@angular/cdk/platform';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, filter, map } from 'rxjs';
import { icons } from 'src/assets/images/icons';
import { DataService } from './core/services/dataService/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'bavox';

  showOnlineAlert: boolean = false;
  initialized: boolean = false;
  isOnline!: boolean;
  modalVersion!: boolean;
  modalPwaEvent: any;
  modalPwaPlatform: string|undefined;
  installApp!: boolean;
  platformPrompt: boolean = false;

  public actionIcon: string = icons.actionIcon;
  public addShortcutIcon: string = icons.addShortcutIcon;

  isNewAppVersionAvailable: boolean = false;
  newAppUpdateAvailableSubscription!: Subscription;

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private platform: Platform,
    private swUpdate: SwUpdate,
    private readonly ability: AppAbility,
  ) {
    this.isOnline = false;
    this.modalVersion = false;
  }
  
  ngOnInit() {
    // window.addEventListener('online', () => {
    //   this.dataService.synchronizeData(token);
    // });

    window.ononline = () => {
      this.dataService.synchronizeData();
    };

    this.checkRole();
    
    this.updateOnlineStatus();
    window.addEventListener('online',  this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates.pipe(
        filter((evt: any): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map((evt: any) => {
          this.modalVersion = true;
        }),
      );
    }

    this.loadModalPwa();
  }

  private updateOnlineStatus(): void {
    const wasOnline = this.isOnline;
    this.isOnline = navigator.onLine;
    if (this.isOnline && !wasOnline && this.initialized) {
      this.showOnlineAlert = true;
      setTimeout(() => this.showOnlineAlert = false, 4000);
    }
    if (!this.initialized) {
      this.initialized = true;
    }
  }

  public updateVersion(): void {
    this.modalVersion = false;
    window.location.reload();
  }

  public closeVersion(): void {
    this.modalVersion = false;
  }

  private loadModalPwa(): void {
    this.platformPrompt = true;
    this.installApp = !!localStorage.getItem('installApp');
    if (this.platform.ANDROID) {
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        this.modalPwaEvent = event;
        this.modalPwaPlatform = 'ANDROID';
      });
    }

    if (this.platform.IOS && this.platform.SAFARI) {
      const isInStandaloneMode = ('standalone' in window.navigator) && ((<any>window.navigator)['standalone']);
      if (!isInStandaloneMode) {
        this.modalPwaPlatform = 'IOS';
      }
    }
  }

  public addToHomeScreen(): void {
    this.modalPwaEvent.prompt();
    this.modalPwaPlatform = undefined;
  }

  public closePwa(): void {
    localStorage.setItem('installApp', 'true');
    this.platformPrompt = false;
    // this.modalPwaPlatform = undefined;
  }

  private checkRole(){
    const role = this.authService.getUserRole();
    this.ability.update(defineAbilitiesFor(role));
  }

}
