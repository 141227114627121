// @ts-nocheck comment
import {Injectable} from '@angular/core';
import {BotatoChatStorageService} from './botato-chat-storage.service';

@Injectable({
    providedIn: 'root'
})
export class BotatoUserService {
    private userId: string;

    constructor(private storageService: BotatoChatStorageService) {
    }

    public getUserId(botId: string): Promise<string> {
        if (this.userId === undefined) {
            return this.createUserId(botId).then((userId: string) => {
                this.userId = userId;
                return this.userId;
            });
        }
        const botInUserId = this.userId.split('.');
        if (botInUserId.length === 1) {
            return Promise.resolve(this.userId);
        } else {
            if (botInUserId[0] !== botId) {
                this.userId = botId + '.' + botInUserId[1];
                this.storageService.setItem('userid', this.userId)
                    .catch(error => console.log(error));
            }
            return Promise.resolve(this.userId);
        }
    }

    private createUserId(botId: string): Promise<string> {
        return this.storageService.getItem('userid').then(userId => {
            if (botId !== undefined) {
                if (userId === null) {
                    userId = this.createUserIdWithBotId(botId);
                } else {
                    const botInUserId = userId.split('.');
                    if (botInUserId[0] !== botId) {
                        userId = this.createUserIdWithBotId(botId);
                    }
                }
            } else {
                if (userId === null) {
                    userId = (Math.floor((Math.random() * 10000000000000))).toString();
                }
            }
            this.storageService.setItem('userid', userId)
                .catch(error => console.log(error));
            return userId;
        });
    }

    private createUserIdWithBotId(botId: string): string {
        const userIdStorage = (Math.floor((Math.random() * 10000000000000))).toString();
        return botId + '.' + userIdStorage;
    }
}
