import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { BotComponent } from './feature/chatbot/bot/bot.component';

import { CameraComponent } from './feature/camera/camera.component';
// import { RoleGuard } from './core/guards/role.guard';

const routes: Routes = [
{
  path: '',
  loadChildren: () => import('./dashboard/dashboard.module')
  .then(m => m.DashboardModule),
  canActivate: [AuthGuard]
},
{
  path: 'tasks',
  loadChildren: () => import('./tasks/tasks.module')
  .then(m => m.TasksModule)
},
{
  path: 'organization',
  loadChildren: () => import('./organization/organization.module')
  .then(m => m.OrganizationModule),
  canActivate: [AuthGuard]
},
{
  path: 'projects',
  loadChildren: () => import('./projects/projects.module')
  .then(m => m.ProjectsModule),
},
{
  path: 'profile',
  loadChildren: () => import('./profile/profile.module')
  .then(m => m.ProfileModule),
  canActivate: [AuthGuard]
},
{
  path: 'auth',
  loadChildren: () => import('./auth/auth.module')
  .then(m => m.AuthModule),
},
{
  path: 'chat/:projectId/:mode/:journalId',
  component: BotComponent,
  canActivate: [AuthGuard],
},
{
  path: 'camera/:projectId/:mode/:journalId/:view/note/:noteId',
  component: CameraComponent
},
{
  path: 'camera/shared/:sharedLinkId/:mode/:journalId/:view/note/:noteId',
  component: CameraComponent
},
{
  path: '**',
  component: NotFoundComponent
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
