import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BotatoConfig } from 'src/app/libs/botato-angular-lib/botato.config';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socket!: Socket;

  constructor(
    private authService: AuthService,
    @Inject('BOTATO_CONFIG') private botatoConfig: BotatoConfig
  ) { }

  connectSocket() {
    const token = this.authService.getToken();
    if(token){
      this.socket = io(this.botatoConfig.socketUrl, {
          path: this.botatoConfig.socketPath,
          extraHeaders: {
              token: token
          }
      });
    }
  }

  disconnectSocket(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
