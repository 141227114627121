import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  private envUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  listEntities(token: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/entities`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    );
  }

  filterInformation(token: string | null) {
    return this.http.get(`${this.envUrl}/projects/filter`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res:any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  listTasks(token: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  getTaskById(token: string | null, taskId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/${taskId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  createTask(token: string | null, body: any): Observable<any> {
    return this.http.post(`${this.envUrl}/tasks`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res),
      catchError((error: any): Observable<any> => error)
    );
  }

  updateTask(token: string | null, taskId: string | null, body: any): Observable<any> {
    return this.http.put(`${this.envUrl}/tasks/${taskId}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteTask(token: string | null, taskId: string | null): Observable<any> {
    return this.http
      .delete(`${this.envUrl}/tasks/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '555'
        }
      })
      .pipe(
        map((res) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  addImage(token: string | null, taskId: string | null, data: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('files', data);

    return this.http.put(`${this.envUrl}/tasks/${taskId}/picture`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  listComments(token: string | null, taskId: string | null, ): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/${taskId}/comments`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  getCommentById(token: string | null, taskId: string | null, commentId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/${taskId}/comments/${commentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  addComment(token: string | null, taskId: string | null, body: any): Observable<any> {
    return this.http.post(`${this.envUrl}/tasks/${taskId}/comments`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  updateComment(token: string | null, taskId: string | null, commentId: string | null, body: any): Observable<any> {
    return this.http.put(`${this.envUrl}/tasks/${taskId}/comments/${commentId}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  deleteComment(token: string | null, taskId: string | null, commentId: string | null): Observable<any> {
    return this.http.delete(`${this.envUrl}/tasks/${taskId}/comments/${commentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  deleteImage(token: string | null, taskId: string | null, pictureKey: string | null): Observable<any> {
    return this.http.delete(`${this.envUrl}/tasks/${taskId}/picture/${pictureKey}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .pipe(
        map((res) => res),
        catchError((error: any): Observable<any> => error)
      )
  }

  addImageToSharedTasks(sharedLinkId: string | null, taskId: string | null, data: any): Observable<any> {
    let formData: FormData = new FormData();
    formData.append('files', data);

    return this.http.put(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/picture`, formData, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  listSharedTasks(token: string | null, sharedLinkId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/shared/${sharedLinkId}`, {
      headers: {
        Authorization: !!token ? `Bearer ${token}` : '',
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }
  
  getSharedTaskById(token: string | null, sharedLinkId: string | null, taskId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}`, {
      headers: {
        Authorization: !!token ? `Bearer ${token}` : '',
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  updateSharedTask(sharedLinkId: string | null, taskId: string | null, body: any): Observable<any> {
    return this.http.put(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}`, body, {
        headers: {
          'ngrok-skip-browser-warning': '555'
        },
      })
      .pipe(
        map((res: any) => res.data),
        catchError((error: any): Observable<any> => error)
      );
  }

  deleteSharedTaskImage(sharedLinkId: string | null, taskId: string | null, pictureKey: any): Observable<any> {
    return this.http.delete(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/picture/${pictureKey}`, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  // shared link comments
  addExternalSharedComment(sharedLinkId: string | null, taskId: string | null, body: any): Observable<any> {
    return this.http.post(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments/external`, body, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  addSharedComment(token: string | null, sharedLinkId: string | null, taskId: string | null, body: any): Observable<any> {
    return this.http.post(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

  getSharedCommentById(sharedLinkId: string | null, taskId: string | null, commentId: string | null): Observable<any> {
    return this.http.get(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments/${commentId}`, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  listSharedComment(sharedLinkId: string | null, taskId: string | null, token?: string | null): Observable<any> {
    let headers = new HttpHeaders({
      'ngrok-skip-browser-warning': '555'
    });

    // Add Authorization header only if token is not null or undefined
    if (token) {
      headers = headers.append('Authorization', `Bearer ${token}`);
    }
    
    return this.http.get(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments`, {
      headers
    })
    .pipe(
      map((res: any) => res.data),
      catchError((err: any): Observable<any> => err)
    )
  }

  updateSharedComment(sharedLinkId: string | null, taskId: string | null, commentId: string | null, body: any): Observable<any> {
    return this.http.put(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments/${commentId}`, body, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res: any) => res.data),
      catchError((error: any): Observable<any> => error)
    );
  }

  deleteSharedComment(sharedLinkId: string | null, taskId: string | null, commentId: string | null): Observable<any> {
    return this.http.delete(`${this.envUrl}/tasks/shared/${sharedLinkId}/${taskId}/comments/${commentId}`, {
      headers: {
        'ngrok-skip-browser-warning': '555'
      }
    })
    .pipe(
      map((res) => res),
      catchError((error: any): Observable<any> => error)
    )
  }

}
