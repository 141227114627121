import {Inject, NgModule} from '@angular/core';
import {BotatoChatComponent} from './botato-chat/botato-chat.component';
import {BotatoChatMessageComponent} from './botato-chat-message/botato-chat-message.component';
import {ContenteditableModelDirective} from './contenteditable-model.directive';
import {CommonModule} from '@angular/common';
import {TextMessageComponent} from './botato-chat-message/message-type/text-message/text-message.component';
import {BotatoChatMessageDirective} from './botato-chat-message/botato-chat-message.directive';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BotatoConfig} from './botato.config';
import {EmojiPipe} from './emoji.pipe';
import {BotatoChatInputComponent} from './botato-chat-input/botato-chat-input.component';
import {BotatoChatInputDirective} from './botato-chat-input/botato-chat-input.directive';
import {TextInputComponent} from './botato-chat-input/input-type/text-input/text-input.component';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {VoiceInputComponent} from './botato-chat-input/input-type/text-input/voice/voice-input.component';
import {TypingIndicatorComponent} from './botato-chat-message/message-type/typing-indicator/typing-indicator.component';
import { SharedModule } from "../../shared/shared.module";

@NgModule({
    declarations: [
        BotatoChatComponent,
        BotatoChatMessageComponent,
        BotatoChatInputComponent,
        ContenteditableModelDirective,
        TextMessageComponent,
        BotatoChatMessageDirective,
        BotatoChatInputDirective,
        TextInputComponent,
        TypingIndicatorComponent,
        EmojiPipe,
        VoiceInputComponent
    ],
    exports: [
        BotatoChatComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        SharedModule
    ]
})
export class BotatoAngularLibBaseModule {

    constructor(translateService: TranslateService, @Inject('BOTATO_CONFIG') botatoConfig: BotatoConfig) {
        for (const language of botatoConfig.translations) {
            translateService.setTranslation(language.language, language.translations);
        }

        if (botatoConfig.defaultLanguage === undefined) {
            throw new Error('No defaultLanguage defined in BotatoConfig. You have to specify it in the BotatoConfig passed to the BotatoAngularLibLocalModule or BotatoAngularLibModule!');
        }

        if (!botatoConfig.translations.some(lang => lang.language === botatoConfig.defaultLanguage)) {
            throw new Error(`Could not find the defined defaultLanguage (${botatoConfig.defaultLanguage}) in the given list of translations. Available translations are: ${botatoConfig.translations.map(x => x.language).join(', ')}`);
        }

        translateService.use(botatoConfig.defaultLanguage);
    }
}
