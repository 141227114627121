import { Component, Input, AfterViewInit, OnInit, ViewChild, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

interface itemType {
  itemName: string,
  itemValue: string
};

@Component({
  selector: 'app-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss']
})
export class DropDownComponent implements OnInit, OnDestroy {
  @Input() control: FormControl = new FormControl;

  @Input() label?: string = 'Select Option';  
  @Input() requiredText?: string | null = null;
  @Input() placeholder?: string | null = null;
  
  @Input() items: itemType[] | null = null;

  constructor(){}
  
  public selectedItem: itemType | null = null;
  private valueChangesSubscription!: Subscription;

  ngOnInit() {
    // Subscribe to the form control value changes
    this.valueChangesSubscription = this.control.valueChanges.subscribe(value => {
      this.updateSelectedItem(value);
    });

    // Initialize selected item based on the initial value
    this.updateSelectedItem(this.control.value);
  }

  ngOnDestroy() {
    // Unsubscribe to avoid memory leaks
    this.valueChangesSubscription.unsubscribe();
  }

  updateSelectedItem(value: any) {
    if (!value) {
      this.selectedItem = null;
    } else {
      this.selectedItem = this.items?.find(item => item.itemValue === value) || null;
    }
  }

  handleClick = () => {
    const elem = document.activeElement as HTMLElement;
    if(elem){
      elem?.blur();
    }
  };

  selectItem(item: itemType){
    this.selectedItem = item;
    this.control.markAsDirty();
    this.control.markAsTouched();
    this.control.setValue(item.itemValue, { emitEvent: true });
    this.handleClick();
  }

  controlValueItemName(controlValue: any){
    const item = this.items?.find(item => item.itemValue === controlValue);
    return item?.itemName;
  }

  @HostListener('focusin', ['$event'])
  onFocusIn(event: FocusEvent): void {
    this.isFocused = true;
    this.hiddenSelect.nativeElement.focus();
  }

  @HostListener('focusout', ['$event'])
  onFocusOut(event: FocusEvent): void {
    this.isFocused = false;
    this.control.markAsTouched();
  }

  isFocused: boolean = false;
  @ViewChild('hiddenSelect') hiddenSelect!: ElementRef;


}
