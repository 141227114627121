// @ts-nocheck comment
import {Component, Input, ViewEncapsulation} from '@angular/core';
import {MessageViewModel} from '../../../viewmodel/MessageViewModel';
import {MessageType} from '../message-type';
import {ResponseDtoType} from '../../../dto/ResponseDtoType';
import { icons } from 'src/assets/images/icons';

@Component({
    templateUrl: './text-message.component.html',
    encapsulation: ViewEncapsulation.None
})
export class TextMessageComponent implements MessageType {
    showMore: string = icons.verticalShowMoreIcon;
    editIcon: string = icons.editIcon;
    trashIcon: string = icons.trashIcon;

    public static TYPE: ResponseDtoType = 'text';

    @Input()
    public message: MessageViewModel;

}
