// @ts-nocheck comment
import {MessageGroupViewModel, SenderType} from '../viewmodel/MessageGroupViewModel';
import {MessageViewModelFactory} from '../viewmodel/MessageViewModelFactory';
import {ResponseDto} from '../dto/ResponseDto';
import {Injectable} from '@angular/core';
import {DialogViewModel} from '../viewmodel/DialogViewModel';
import {BehaviorSubject, Observable} from 'rxjs';
import {MessageViewModel} from '../viewmodel/MessageViewModel';
import {QuickReplyDto} from '../dto/QuickReplyDto';

@Injectable()
export class DialogViewModelCreatorService {
    private dialogViewModelSubject: BehaviorSubject<DialogViewModel>;
    private messageViewModels: MessageViewModel[] = [];
    private typingIndicatorFlag: boolean = false;

    constructor() {
        this.dialogViewModelSubject = new BehaviorSubject<DialogViewModel>(this.createDialogViewModel());
    }

    public showTypingIndicator(): void {
        this.typingIndicatorFlag = true;

        this.emitDialogViewModel();
    }

    public addBotMessage(responseDto: ResponseDto): void {
        this.typingIndicatorFlag = false;
        //console.log(responseDto)
        this.messageViewModels.push(MessageViewModelFactory.createFromServerDto(responseDto));

        this.emitDialogViewModel();
    }

    public addUserTextMessage(text: string): void {
        this.messageViewModels.push(MessageViewModelFactory.createFromClientText(text));

        this.emitDialogViewModel();
    }

    public addHistory(history: (ResponseDto | string)[]): void {
        this.clearDialog();
        history = history.reverse();
        for (const historyEntry of history) {
            if (typeof historyEntry === 'string') {
                this.messageViewModels.unshift(MessageViewModelFactory.createFromClientText(historyEntry));
            } else {
                this.messageViewModels.unshift(MessageViewModelFactory.createFromServerDto(historyEntry));
            }
        }

        this.emitDialogViewModel();
    }

    public clearDialog(): void {
        this.messageViewModels = [];

        this.emitDialogViewModel();
    }

    public getDialogViewModelObservable(): Observable<DialogViewModel> {
        return this.dialogViewModelSubject.asObservable();
    }

    private emitDialogViewModel(): void {
        const dialogViewModel = this.createDialogViewModel();
        this.dialogViewModelSubject.next(dialogViewModel);
    }

    private createDialogViewModel(): DialogViewModel {
        const messageGroups: MessageGroupViewModel[] = this.createMessageGroups();
        const quickReplies: QuickReplyDto[] = this.createQuickReplies();

        if (this.typingIndicatorFlag) {
            this.addTypingIndicator(messageGroups);
        }
        return new DialogViewModel(messageGroups, quickReplies);
    }

    private addTypingIndicator(messageGroups: MessageGroupViewModel[]): void {
        if (this.messageViewModels.length === 0) {
            messageGroups.push(new MessageGroupViewModel([MessageViewModelFactory.createTypingIndicator()], 'server'));
        } else {
            const lastMessageGroup = messageGroups[messageGroups.length - 1];
            const sender = lastMessageGroup.sender;
            if (sender === 'client') {
                messageGroups.push(new MessageGroupViewModel([MessageViewModelFactory.createTypingIndicator()], 'server'));
            } else if (sender === 'server') {
                lastMessageGroup.messages.push(MessageViewModelFactory.createTypingIndicator());
            }
        }
    }

    private createQuickReplies(): QuickReplyDto[] {
        if (this.messageViewModels.length > 0 && this.messageViewModels[this.messageViewModels.length - 1].sender === 'server' && !this.typingIndicatorFlag) {
            return this.messageViewModels[this.messageViewModels.length - 1].quickReplies;
        }
        return undefined;
    }

    private createMessageGroups(): MessageGroupViewModel[] {
        const messageGroups: MessageGroupViewModel[] = [];
        let messages: MessageViewModel[];
        let activeGroupSender: SenderType;

        for (let i = 0; i < this.messageViewModels.length; i++) {
            const element = this.messageViewModels[i];
            const hasSenderChanged = element.sender !== activeGroupSender;
            if (hasSenderChanged) {
                activeGroupSender = element.sender;
                messages = [];
                const messageGroupViewModel = (element.sender === 'server') ? new MessageGroupViewModel(messages, activeGroupSender, element.botAvatarImageUrl) : new MessageGroupViewModel(messages, activeGroupSender);
                messageGroups.push(messageGroupViewModel);
            }
            messages.push(element);
        }
        return messageGroups;
    }
}
