import {Injectable, Type} from '@angular/core';
import {TextMessageComponent} from './message-type/text-message/text-message.component';
import {ResponseDtoType} from '../dto/ResponseDtoType';
import {MessageType} from './message-type/message-type';
import {TypingIndicatorComponent} from './message-type/typing-indicator/typing-indicator.component';

@Injectable()
export class BotatoChatMessageResolverService {

    public messageMapping = new Map<string, Type<MessageType>>();

    constructor() {
        this.messageMapping.set(TextMessageComponent.TYPE, TextMessageComponent);
        this.messageMapping.set(TypingIndicatorComponent.TYPE, TypingIndicatorComponent);
    }

    public resolve(messageType: ResponseDtoType): Type<MessageType> {
        const mesageTypeComponent = this.messageMapping.get(messageType);
        if (mesageTypeComponent === undefined) {
            throw new Error(`No MessageComponent available for type '${messageType}'. IMPORTANT: This could happen because there are multiple imports of BotatoBotengineBrowser, which influences the instanceof in MessageRouter.sendSingleItem().`);
        }
        return mesageTypeComponent;
    }
}


