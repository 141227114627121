<div class="chat chat-start !gap-0">
    <div class="chat-image avatar">
      <div class="w-[50px]">
        <!-- <img class="!object-contain transform -scale-x-100" src="../../../../assets/images/logo/bavox-mascot.svg" /> -->
      </div>
    </div>
    <div class="flex items-end gap-1">
      <div class="chat-bubble !min-h-0 !bg-[#EDF3E6] rounded-md before:!content-none">
        <h1 class="text-[#81BE3F] font-medium text-base text-left">
          Standort definiert
        </h1>
        <p *ngIf="message.data.location.building" class="text-left text-base font-normal break-words">{{message.data.location.building.name}} {{message.data.location.building.number}}</p>
        <p *ngIf="message.data.location.floor" class="text-left text-base font-normal break-words">{{message.data.location.floor.name}} {{message.data.location.floor.number}} </p>
        <p *ngIf="message.data.location.unit" class="text-left text-base font-normal break-words">{{message.data.location.unit.name}} {{message.data.location.unit.number}} </p>
        <p *ngIf="message.data.location.room" class="text-left text-base font-normal break-words">{{message.data.location.room.name}} {{message.data.location.room.number}}</p>
      </div>
      <div class="chat-footer opacity-50">
        {{message.date | date: "HH:mm"}}
      </div>
    </div>
  </div>
