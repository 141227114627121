// @ts-nocheck comment
import {Component, OnInit, ViewEncapsulation} from '@angular/core';

// Replace this with your
import { BavoxLocationDto } from '../../../infrastructure/model/BavoxLocationDto';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { JournalDto } from '../../../infrastructure/model/JournalDto';
import { BkpDto } from '../../../infrastructure/model/BkpDto';
import { DeletedMessagesHolder } from '../message-components/note/deletedMessagesHolder';
import { BotatoChatManagerService } from '../../../libs/botato-angular-lib/service/botato-chat-manager.service';
import { DialogViewModelCreatorService } from '../../../libs/botato-angular-lib/service/dialog-view-model-creator.service';
import { CommunicationService } from '../../../libs/botato-angular-lib/service/communication.service';
import { icons } from 'src/assets/images/icons';
import { ProjectsService } from 'src/app/projects/services/projects.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';

export type JournalType = 'journal' | 'creationAcceptance' | 'objectAcceptance';

@Component({
  selector: 'app-bavox-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BotComponent implements OnInit {
  public chevronLeft: string = icons.chevronLeft;
  public menu: string = icons.hamburgerMenu;
  public exitIcon: string = icons.exitIcon;
  public chevronRight: string = icons.chevronRight;
  public exclamationMarkCircle: string = icons.exclamationMarkCircle;

  // new
  public chevronUp: string = icons.chevronUp;
  public searchIcon: string = icons.searchIcon;
  public searchFilter: string = icons.searchFilter;
  public plusIcon: string = icons.plus;
  public eyeIcon: string = icons.eye;
  public trashIcon: string = icons.trashCanIcon;
  public showMore: string = icons.verticalShowMoreIcon;
  public editIcon: string = icons.editIcon;

  public searchDocument: string | null = null;

  public showDocumentModal: boolean = false;
  public selectedDocumentKey: string | null = null;
  public selectedDocumentUrl: string | Uint8Array | undefined = undefined;
  public selectedDocumentType: string | null = null;
  
  public listDocumentCategories: any = [];
  public listDocumentTags: any = [];
  public filteredListOfTags: any = this.listDocumentTags;

  public searchFilterString: string | null = null;
  public selectedFilterCategory: any = [];
  public selectedFilterTags: any = [];

  public dropdown: any = [];

  public dropdownOpen: { [key: string]: boolean } = {};
  public subDropdownOpen: { [key: string]: boolean } = {};

  public token: string | null = null;
  public journal!: JournalDto;
  public location!: BavoxLocationDto;
  public bkp!: BkpDto;
  public projectId!: string;
  private journalId!: string;
  public mode!: JournalType;

	public showModal: boolean = false;

  constructor(
    private chatManager: BotatoChatManagerService,
    private dialogViewModelCreatorService: DialogViewModelCreatorService,
    // private bavoxService: BavoxApiService,
    private bavoxService: ProjectsService,
    private route: ActivatedRoute,
    private router: Router,
    private locationService: Location,
    private communicationService: CommunicationService,
    private projectsService: ProjectsService,
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {}

  public async ngOnInit(): Promise<void> {
    this.token = this.authService.getToken();
    this.projectId = this.route.snapshot.params['projectId'] as string;
    this.mode = this.route.snapshot.params['mode'] as JournalType;
    this.journalId = this.route.snapshot.params['journalId'] as string;

    this.bavoxService.getJournalById(this.token, this.journalId).subscribe({
      next: (res) => {
        this.journal = res;
      },
    });

    const lastViewedJournalId = localStorage.getItem('journalId');
    const dialog = await firstValueFrom(
      this.dialogViewModelCreatorService.getDialogViewModelObservable()
    );
    const hasNewJournalId = lastViewedJournalId !== this.journalId;
    const hasChatHistory = dialog.messageGroups.length > 0;
    if (hasNewJournalId || !hasChatHistory) {
      this.restartChat();
      localStorage.setItem('journalId', this.journalId);
      localStorage.removeItem('bkp');
      localStorage.removeItem('location');
    } else {
      // eslint-disable-next-line no-null/no-null
      if (localStorage.getItem('location') !== null) {
        this.location = JSON.parse(
          localStorage.getItem('location')
        ) as BavoxLocationDto;
      }
      // eslint-disable-next-line no-null/no-null
      if (localStorage.getItem('bkp') !== null) {
        this.bkp = JSON.parse(localStorage.getItem('bkp')) as BkpDto;
      }
    }

    this.communicationService.messageReceived.subscribe(
      async (botResult: {
        additionalData: { location: BavoxLocationDto; bkp: BkpDto };
      }) => {
        if (botResult.additionalData.location !== undefined) {
          localStorage.setItem(
            'location',
            JSON.stringify(botResult.additionalData.location)
          );
          this.location = botResult.additionalData.location;
        }

        if (botResult.additionalData.bkp !== undefined) {
          localStorage.setItem(
            'bkp',
            JSON.stringify(botResult.additionalData.bkp)
          );
          this.bkp = botResult.additionalData.bkp;
        }
      }
    );
    this.focusInput();

    this.listDocumentsByProject();
    this.listDocumentEntities();
  }

  listDocumentsByProject() {
    this.projectsService.listDocumentsByProject(this.token, this.projectId).subscribe({
      next: (res) => {
        const groupedByCategory = res.documents.reduce((acc: any, doc: any) => {
          // Find if the category already exists in the accumulator
          const existingCategory = acc.find((group: any) => group.category === doc.category);
          
          if (existingCategory) {
              // If the category exists, push the document into the documents array
              if(this.isDateValid(doc.expirationDate)){
                existingCategory.documents.push(doc);
              }
          } else {
              // If the category does not exist, create a new group
              if(this.isDateValid(doc.expirationDate)){
                acc.push({
                    category: doc.category,
                    documents: [doc]
                });
              }
          }
          return acc;
        }, []);
        
        this.dropdown = groupedByCategory;
        this.listDocumentEntities();
      }
    })
  };

  listDocumentEntities() {
    this.projectsService.listDocumentEntities(this.token, this.projectId).subscribe({
      next: (res) => {
        this.listDocumentCategories = res.categories;
        this.listDocumentTags = res.tags;
        this.filteredListOfTags = res.tags;
      }
    })
  };

  public focusInput(): void {
    this.chatManager.focusInput();
  }

  public restartChat(): void {
    this.dialogViewModelCreatorService.clearDialog();
    DeletedMessagesHolder.clear();
    this.chatManager.start();
  }

  public formatInfoBarText(): string {
    return [this.formatLocationText(), this.formatBKP()]
      .filter((x) => x !== undefined)
      .join(' / ');
  }

  private formatLocationText(): string | undefined {
    if (this.location === undefined) {
      return undefined;
    }

    if (this.location.room !== undefined) {
      return `${this.location.room.name ?? ''} ${
        this.location.room.number ?? ''
      }`;
    }

    if (this.location.unit !== undefined) {
      return `${this.location.unit.name ?? ''} ${
        this.location.unit.number ?? ''
      }`;
    }

    if (this.location.floor !== undefined) {
      return `${this.location.floor.name ?? ''} ${
        this.location.floor.number ?? ''
      }`;
    }

    if (this.location.building !== undefined) {
      return `${this.location.building.name ?? ''} ${
        this.location.building.number ?? ''
      }`;
    }

    return undefined;
  }

  private formatBKP(): string | undefined {
    if (this.bkp === undefined) {
      return undefined;
    }

    return `${this.bkp.number} - ${this.bkp.description}`;
  }

  public async toggleView(): Promise<void> {
    await this.router.navigate([
      'projects',
      this.projectId,
      this.mode,
      this.journalId,
      'overview',
    ]);
  }

  public goToTaskPage(): void {
    // this.route.params.subscribe(params => {
    //     const { projectId, journalId, mode } = params;
    //     this.router.navigate(['/tasks', projectId, journalId, mode]);
    // })
    this.locationService.back();
  }

	toggleModal() {
		this.showModal = !this.showModal;
	}

  onCheckboxChange(item: any, event: any): void {
    const targetName = event.target.name;
    const targetChecked = event.target.checked;
    
    if (targetChecked) {
      switch (targetName) {
        case 'filter_document_category':
          this.selectedFilterCategory = [...this.selectedFilterCategory, item];
          break;
        case 'filter_document_tags':
          this.selectedFilterTags = [...this.selectedFilterTags, item];
          break;
  
        default:
          console.warn(`Unknown filter type: ${targetName}`);
          break;
      }
    };

    if (!targetChecked) {
      switch (targetName) {
        case 'filter_document_category':
          this.selectedFilterCategory = this.selectedFilterCategory.filter((selectedItem: any) => selectedItem !== item);
          break;
        case 'filter_document_tags':
          this.selectedFilterTags = this.selectedFilterTags.filter((selectedItem: any) => selectedItem !== item);
          break;

        default:
          console.warn(`Unknown filter type: ${targetName}`);
          break;
      }
    };

    this.updateFilteredTags();

  };

  checkedFilter(filterType: string, item: any): boolean {
    const isEqual = (objA: any, objB: any): boolean => {
      return JSON.stringify(objA) === JSON.stringify(objB);
    };
    
    if (filterType === 'filter_document_category') {
      return this.selectedFilterCategory.some((filterCategory: any) => isEqual(filterCategory, item));
    } else if (filterType === 'filter_document_tags') {
      return this.selectedFilterTags.some((filterTag: any) => isEqual(filterTag, item));
    } else {
      return false;
    }
  };

  clearAllFilters() {
    this.selectedFilterCategory = [];
    this.selectedFilterTags = [];
    this.searchFilterString = null;
  };

  // Function to update the filtered tags based on selected categories
  updateFilteredTags(): void {
    if (this.selectedFilterCategory.length === 0) {
      // If no categories are selected, show the full list of tags
      this.filteredListOfTags = [...this.listDocumentTags];
      return;
    }

    const tagsSet = new Set<string>();

    // Filter documents by selected categories
    this.dropdown.forEach((doc: any) => {
      if (this.selectedFilterCategory.includes(doc.category)) {
        // Collect all tags from the filtered documents
        doc.documents.forEach((document: any) => {
          const listTag = this.listDocumentTags.find((tag: any) => documetTag === tag.name);
          tagsSet.add(listTag);
        });
      }
    });

    // Convert Set to array for filtered tags
    this.filteredListOfTags = Array.from(tagsSet);
  }

  setDocumentUrl(url: string, key:string) {
    this.showDocumentModal = !this.showDocumentModal;
    this.selectedDocumentUrl = url;
    this.selectedDocumentType = this.extractFileType(key);
    
  }

  extractFileType(fileName: string) {
    // Regex to match file extensions 'pdf', 'png', 'jpg', 'jpeg'
    const regex = /\.(pdf|png|jpg|jpeg)$/;
    const match = fileName.match(regex);

    // If a match is found, return the matched group (file extension)
    // Otherwise, return 'undefined' or an appropriate message/error
    return match ? match[1] : null;
  }

  closeDocumentModal() {
    this.selectedDocumentUrl = null;
    this.showDocumentModal = !this.showDocumentModal;
  };

  toggleDropdown(key: number) {
    const keyString = JSON.stringify(key);
    this.dropdownOpen[keyString] = !this.dropdownOpen[keyString];
  };

  toggleSubDropdown(key: string) {
    this.subDropdownOpen[key] = !this.subDropdownOpen[key];
  };

  toggleFilterDropdown(menu: HTMLElement, submenu: HTMLElement): void {
    menu.classList.toggle('menu-dropdown-show');
    submenu.classList.toggle('menu-dropdown-show');
  };

  isDateValid(validUntil: string): boolean {
    if(!!validUntil) {
      // Parse the date from the backend
      const validDate = moment(validUntil);
    
      // Get today's date at the start of the day (00:00:00)
      const today = moment().startOf('day');
    
      // Compare the two dates
      return validDate.isSameOrAfter(today);
    }
    return true;
  };

}
