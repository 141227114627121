import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
  pure: false
})
export class FilterByPipe implements PipeTransform {

  transform(items: any[], filterStrings: any, propertyName: string) {
    if (!items || !filterStrings || filterStrings.length === 0 || !propertyName) {
      return items;
    }
  
    const getPropertyAsString = (obj: any, path: string[]): string => {
      const value = path.reduce((prev, curr) => prev && prev[curr], obj);
      // Convert any value to a string; handle null/undefined with an empty string
      return value != null ? value.toString().toLowerCase() : '';
    };
    
    return items.filter(item => {
      const nestedProperties = propertyName.split('.');
      const itemValue = getPropertyAsString(item, nestedProperties);

      // If filterStrings is an array of strings
      if (typeof filterStrings[0] === 'string') {
        return filterStrings.some((filterString: string) => {
          return itemValue.includes(filterString.toLowerCase());
        });
      }

      // If filterStrings is an array of objects (apply the original logic)
      return filterStrings.some((filterItem: any) => {
        const filterValue = getPropertyAsString(filterItem, nestedProperties);
        return itemValue === filterValue;
      });
    });
  }
}
