import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @ViewChild('dateInput') dateInput!: ElementRef;

  @Input() control: FormControl<any> = new FormControl();
  @Input() class?: string | null = null;
  @Input() label?: string | null = null;
  @Input() requiredText?: string | null = null;
  @Input() placeholder?: string | null = null;

  today: Date = new Date();
  formattedDate: string = this.today.toISOString().split('T')[0];

  constructor(){}
  ngOnInit() {}
  
  onInputFocus(): void {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isIOS) {
      this.dateInput.nativeElement.focus();
    } else {
      this.dateInput.nativeElement.showPicker();
    }
  }

  onBlur(): void {
    const control = this.control;
    if (control) {
      control.markAsDirty();
      control.markAsTouched();
    }
  }

}
