// @ts-nocheck comment
import {DeviceInfoDto} from './DeviceInfoDto';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';

export class WebSocketMessage {
    public userId: string;

    constructor(public language: string,
                public clientId: string,
                public botId: string,
                public additionalData: WebSocketMessageAdditionalData,
                public inputSource?: InputSourceType,
                public authToken?: string) {
    }
}

export class WebSocketMessageAdditionalData {
    deviceInfo: DeviceInfoDto;

    [key: string]: unknown;
}
