import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private imagesSource = new BehaviorSubject<File[]>([]);

  currentImages = this.imagesSource.asObservable();

  constructor() { }

  storeImage(imageInput: File): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        const currentImages = this.imagesSource.value;
        this.imagesSource.next([...currentImages, imageInput]);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }  

  // Delete a specific image by index
  deleteImage(index: number): void {
    const currentImages = this.imagesSource.value;
    currentImages.splice(index, 1);
    this.imagesSource.next([...currentImages]);
  }

  clearImages(): void {
    this.imagesSource.next([]);
  }
}
