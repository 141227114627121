// @ts-nocheck comment
import {ApplicationRef} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {BotatoSpeechRecognitionEvent, BotatoSpeechRecognitionType} from './speech-recognition-types';
import {VoiceRecognitionService} from './voice-recognition.service';
import {DialogConfigTextInputValidation} from '../../../../libs/botato-botengine-browser/Core/DialogConfig/DialogConfig';


declare let webkitSpeechRecognition: {
    prototype: BotatoSpeechRecognitionType;
    new(): BotatoSpeechRecognitionType;
};

export class WebSpeechVoiceRecognitionService implements VoiceRecognitionService {

    public recognition: BotatoSpeechRecognitionType;
    public recognizing = false;
    public finalTranscript: string[] = [];
    public recognitionStopped = false;

    private validation: DialogConfigTextInputValidation;

    private liveTranscript: Subject<string> = new BehaviorSubject<string>('');
    private isRecording: Subject<boolean> = new BehaviorSubject<boolean>(false);
    private finalText: Subject<string> = new Subject();

    public notSupported: boolean = false;

    public liveTranscript$: Observable<string> = this.liveTranscript.asObservable();
    public isRecording$: Observable<boolean> = this.isRecording.asObservable();
    public finalText$: Observable<string> = this.finalText.asObservable();

    constructor(
        private applicationRef: ApplicationRef,
        private translationService: TranslateService
    ) {
        this.init();
    }

    private init(): void {
        if ('webkitSpeechRecognition' in window) {
            this.notSupported = false;

            this.recognition = new webkitSpeechRecognition();

            this.recognition.interimResults = true;
            this.recognition.lang = this.translationService.currentLang;
            this.recognition.continuous = false;

            this.recognition.addEventListener('start', () => {
                if (!this.recognizing) {
                    this.recognizing = true;
                    this.isRecording.next(true);
                }
            });

            this.recognition.addEventListener('end', () => {
                if (this.recognitionStopped) {
                    this.recognizing = false;
                    this.isRecording.next(false);
                    if (this.finalTranscript.length > 0) {
                        if (this.validation !== undefined) {
                            if (new RegExp(this.validation.regex).test(this.buildFinalSentence())) {
                                this.sendMessage();
                            } else {
                                this.finalTranscript = [];
                            }
                        } else {
                            this.sendMessage();
                        }
                    }
                } else {
                    this.recognition.start();
                }
            });

            this.recognition.addEventListener('result', (event: BotatoSpeechRecognitionEvent) => {
                if (typeof (event.results) === 'undefined') {
                    this.recognition.onend = undefined;
                    this.recognition.stop();
                    this.isRecording.next(false);
                    return;
                }

                let tempWords = '';
                for (let i = 0; i < event.results.length; i++) {
                    if (event.results[i].isFinal) {
                        this.finalTranscript.push(event.results[i][0].transcript)
                    } else {
                        tempWords += event.results[i][0].transcript;
                    }
                }

                this.liveTranscript.next([...this.finalTranscript, tempWords].join(' '));
                this.applicationRef.tick();
            });

            this.recognition.addEventListener('error', () => {
                this.recognition.stop();
                this.liveTranscript.next('');
            });
        } else {
            this.notSupported = true;
        }
    }

    public start(inputTextValidation?: DialogConfigTextInputValidation): void {
        if (inputTextValidation !== undefined) {
            this.validation = inputTextValidation;
        }

        if (this.recognizing) {
            this.isRecording.next(false);
            this.recognition.stop();
            return;
        }
        this.recognitionStopped = false;
        this.recognition.start();
        this.isRecording.next(true);
    }

    public stop(): void {
        this.isRecording.next(false);
        this.recognitionStopped = true;
        this.recognition.stop();
    }

    private sendMessage(): void {
        const userUtterance = this.buildFinalSentence();
        this.finalText.next(userUtterance);
        this.finalTranscript = [];
        this.liveTranscript.next('');
        this.applicationRef.tick();
    }

    private buildFinalSentence(): string {
        return this.finalTranscript.join(' ');
    }
}
