<div tabindex="0" class="bg-slate-100 flex min-h-[100vh] relative">
  <div class="relative mx-auto lg:max-w-[1008px] md:max-w-screen-md xs:max-w-screen-xs bg-white shadow-2xl flex-1 overflow-auto">
    
    <div *ngIf="!isOnline" class="fixed top-3 left-1/2 transform -translate-x-1/2 z-50">
      <div class="bg-[#FFF5D0] text-[#FEAA2C] flex items-center justify-center gap-1 py-2 px-4 rounded-3xl w-auto max-w-[90%]">
        <img src="../assets/images/alert/alert-warning.svg" alt="alert icon">
        <h3 class="text-lg font-medium text-center">Offline</h3>
      </div>
    </div>

    <div *ngIf="isOnline" class="fixed top-3 left-1/2 transform -translate-x-1/2 z-50">
      <div *ngIf="showOnlineAlert" class="bg-[#E4F3D3] text-[#92D050] flex items-center justify-center gap-1 py-2 px-4 rounded-3xl w-auto max-w-[90%] animate-fade-out">
        <img src="../assets/images/alert/alert-success.svg" alt="alert icon">
        <h3 class="text-lg font-medium text-center">Online</h3>
      </div>
    </div>
     
    <router-outlet></router-outlet>
  </div>
</div>


<input type="checkbox" id="my_modal_6" class="modal-toggle" [checked]="modalVersion" />
<div class="modal" role="dialog">
  <div class="modal-box max-w-screen-xs">
    Eine neue Version dieser App ist verfügbar.
    <div class="modal-action">
      <label for="my_modal_6" (click)="closeVersion()" class="btn btn-link btn-link-primary">{{'project-overview.close' | translate}}</label>
      <button class="btn btn-primary"  (click)="updateVersion()">Jetzt aktualisieren</button>
    </div>

  </div>
  <label class="modal-backdrop" (click)="closeVersion()" for="my_modal_6">{{'project-overview.close' | translate}}</label>
</div>

<input type="checkbox" id="my_modal_7" class="modal-toggle" [checked]="(modalPwaPlatform === 'ANDROID' || modalPwaPlatform === 'IOS') && !installApp && platformPrompt" />
<div class="modal" role="dialog">
  <div class="modal-box max-w-screen-xs">

    <div class="flex flex-col items-center justify-center gap-4 text-center text-secondary-color-500 font-bold text-xl mb-3">
      <img draggable="false" class="w-[50px]" src="../../../../assets/images/logo/logo-blue.svg" alt="logo">
    </div>

    <!-- Android -->
    <div *ngIf="modalPwaPlatform === 'ANDROID'">
      Fügen Sie diese WEB-App zum Startbildschirm hinzu
    </div>

    <!-- iOS with Safari -->
    <div *ngIf="modalPwaPlatform === 'IOS'" class="flex flex-col gap-3">
      <p>Um diese WEB-App auf Ihrem Gerät zu installieren, tippen Sie auf die Schaltfläche „Menü“.</p>
      <app-svg-icon
        [src]="actionIcon"
        height="32px"
        width="32px"
        fill="black"
      />
      <p>und dann auf die Schaltfläche „Zum Startbildschirm hinzufügen“.</p>
        
      <app-svg-icon
        [src]="addShortcutIcon"
        height="32px"
        width="32px"
        fill="black"
      />
    </div>

    <div class="modal-action">
      <label for="my_modal_7" (click)="closePwa()" class="btn btn-link btn-link-primary">{{'project-overview.close' | translate}}</label>
      <button class="btn btn-primary" (click)="addToHomeScreen()" *ngIf="modalPwaPlatform === 'ANDROID'">App installieren</button>
    </div>

  </div>
  <label class="modal-backdrop" (click)="closePwa()" for="my_modal_7">{{'project-overview.close' | translate}}</label>
</div>