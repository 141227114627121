import {Component, Input} from '@angular/core';
import {MessageType} from '../../../../libs/botato-angular-lib/botato-chat-message/message-type/message-type';
import {MessageViewModel} from '../../../../libs/botato-angular-lib/viewmodel/MessageViewModel';

@Component({
    templateUrl: './bkp.component.html',
    styleUrls: ['./bkp.component.scss']
})
export class BkpComponent implements MessageType {
    public static TYPE = 'BkpItem';

    @Input()
    public message!: MessageViewModel;
}
