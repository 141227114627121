import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterLocations'
})
export class FilterLocationsPipe implements PipeTransform {
  transform(items: any[], filterValues: any[], filterPropPath: string, itemPropPath: string, key: string, key1: string): any[] {
    if (!items || filterValues.length === 0 || !filterPropPath || !itemPropPath) {
      return items;
    }

    // Function to resolve the nested property path
    const resolvePath = (object: any, path: string) => {
      return path.split('.').reduce((o, p) => o ? o[p] : undefined, object);
    };

    // Serialize the filter values considering nested properties
    const filterItemsSerialized = new Set(
      filterValues.flatMap(value => 
        resolvePath(value, filterPropPath) ? resolvePath(value, filterPropPath).map((item: any) => 
          JSON.stringify({
            key: (item[key] ?? '').trim(),
            key1: (item[key1] ?? '').trim()
          })
        ) : []
      )
    );

    return items.filter(item => {
      const targetProp = resolvePath(item, itemPropPath);
      if (!targetProp) {
        return false;
      }

      // Safely serialize the target object with null checks
      const targetSerialized = JSON.stringify({
        key: (targetProp[key] ?? '').trim(),
        key1: (targetProp[key1] ?? '').trim()
      });
      return filterItemsSerialized.has(targetSerialized);
    });
  }
}
