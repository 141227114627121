export class DeletedMessagesHolder {

    private static deletedMessagesIds: string[] = [];

    public static delete(noteId: string): void {
        DeletedMessagesHolder.deletedMessagesIds.push(noteId);
    }

    public static isDeleted(noteId: string): boolean {
        return DeletedMessagesHolder.deletedMessagesIds.some(deletedMessageId => deletedMessageId === noteId);
    }

    public static clear(): void {
        DeletedMessagesHolder.deletedMessagesIds = [];
    }
}
