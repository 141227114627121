import {ResponseDto} from '../dto/ResponseDto';
import {MessageViewModel} from './MessageViewModel';

export class MessageViewModelFactory {

    public static createFromServerDto(dto: ResponseDto): MessageViewModel {
        const viewModel = new MessageViewModel();
        // Data can be any
        // eslint-disable-next-line
        viewModel.data = dto.data;
        viewModel.type = dto.type;
        viewModel.sender = 'server';
        viewModel.quickReplies = dto.quickReplies;
        if (dto.additionalData?.outputOptions?.botAvatarImageUrl !== undefined) {
            viewModel.botAvatarImageUrl = dto.additionalData.outputOptions.botAvatarImageUrl;
        }
        return viewModel;
    }

    public static createFromClientText(text: string): MessageViewModel {
        const viewModel = new MessageViewModel();
        viewModel.data = {text: text};
        viewModel.type = 'text';
        viewModel.sender = 'client';
        return viewModel;
    }

    public static createTypingIndicator(): MessageViewModel {
        const viewModel = new MessageViewModel();
        viewModel.type = 'typingindicator';
        viewModel.sender = 'server';
        return viewModel;
    }
}
