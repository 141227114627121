// @ts-nocheck comment
import {EventEmitter, Injectable} from '@angular/core';
import {ResponseDto} from '../dto/ResponseDto';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';

@Injectable()
export abstract class CommunicationService {

    public lastBotResult: ResponseDto;
    public clientId: string;
    public botId: string;

    public abstract messageReceived: EventEmitter<ResponseDto>;
    public abstract historyReceived: EventEmitter<(ResponseDto | string)[]>;
    public abstract connectionStateChanged: EventEmitter<boolean>;
    public abstract startTyping: EventEmitter<ResponseDto>;

    public abstract welcome(): void;

    public abstract sendMessage(text: string, inputSource: InputSourceType): void;

    // eslint-disable-next-line
    public abstract sendEvent(eventName: string, inputSource: InputSourceType, eventPayload: any): void;

    public abstract setAuthToken(authToken: string): void;
}
