
<div class="pa-meta pa-validation-error" *ngIf="inputOptions.validation !== undefined">
    <div *ngIf="(userText.length > 0 && !validateText(userText)) || validateVoiceText()">
        {{inputOptions.validation.errorMessage}}
    </div>
</div>

<div class="pa-input">
    <div #messageInput contenteditable="true" class="pa-message-input" (keydown)="onEnterPressed($event)"
         (keyup)="onKeyUp()"
         (focus)="onFocus()" [maxLength]="inputOptions.maxInputLength"
         [attr.placeholder]="(inputOptions.placeholder === undefined) ? ('PLACEHOLDER' | translate) : inputOptions.placeholder"
         [(contenteditableModel)]="voiceService.recordedVoice.value? voiceService.tempWords : userText"
         [class.pa-message-input-validation-error]="(userText.length > 0 && !validateText(userText)) || validateVoiceText()">
    </div>
    <button class="pa-send-button" type="button" (click)="sendUserText()" [disabled]="!canSendMessage()"
            *ngIf="!addVoiceInput || (addVoiceInput && (inputFocus && userText.length > 0))">
        <svg class="pa-send-button-icon" aria-hidden="true" data-fa-processed="" data-prefix="fas"
             data-icon="paper-plane" role="img" viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path>
        </svg>
    </button>
    <!-- <botato-text-voice-input [inputTextValidation]="inputOptions?.validation"
                             *ngIf="(addVoiceInput && (!inputFocus || (inputFocus && userText.length === 0))) "></botato-text-voice-input> -->
</div>

<div class="pa-meta" *ngIf="inputOptions.maxInputLength !== 0">
    <div class="pa-letter-count" [class.pa-letter-count-full]="userText.length === inputOptions.maxInputLength">
        {{'CHARACTER_INDICATOR' | translate:{length: userText.length, maxLength: inputOptions.maxInputLength} }}
    </div>
</div>
