import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkAuth(state.url);
  }

  async checkAuth(targetUrl: string): Promise<boolean> {
    try {
      const token = this.authService.getToken();
      if (!token) throw new Error("No token");

      const decodedToken = jwtDecode(token);
      const unixTimestamp: number | undefined = decodedToken.exp;
      const currentTime = new Date().getTime();

      if (unixTimestamp === undefined || unixTimestamp * 1000 < currentTime) {
        throw new Error("Token expired");
      }
    } catch (e) {
      this.authService.redirectUrl = targetUrl;
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
