// @ts-nocheck comment
import {Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MessageViewModel} from '../viewmodel/MessageViewModel';
import {BotatoChatMessageDirective} from './botato-chat-message.directive';
import {BotatoChatMessageResolverService} from './botato-chat-message-resolver.service';
import {MessageType} from './message-type/message-type';

@Component({
    selector: 'botato-chat-message',
    templateUrl: './botato-chat-message.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BotatoChatMessageComponent implements OnInit {

    @ViewChild(BotatoChatMessageDirective, {static: true})
    public messageHost: BotatoChatMessageDirective;

    @Input()
    public message: MessageViewModel;

    constructor(private messageTypeResolver: ComponentFactoryResolver,
                private resolver: BotatoChatMessageResolverService) {
    }

    public ngOnInit(): void {
        const componentFactory = this.messageTypeResolver.resolveComponentFactory(this.resolver.resolve(this.message.type));
        const componentRef = this.messageHost.viewContainerRef.createComponent(componentFactory);
        (componentRef.instance).message = this.message;
    }
}
