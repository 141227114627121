import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { icons } from 'src/assets/images/icons';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  chevronLeft: string = icons.chevronLeft;

  constructor(private location: Location){}

  goBackToPrevPage(): void {
    this.location.back();
  }
}
