import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-view-switch',
    templateUrl: './view-switch.component.html',
    styleUrls: ['./view-switch.component.scss']
})
export class ViewSwitchComponent {

    @Input()
    public selectedView!: 'chat' | 'overview';

    @Output()
    public viewToggled: EventEmitter<void> = new EventEmitter<void>();
}
