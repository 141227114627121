import { Injectable } from '@angular/core';
import Dexie from 'dexie';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends Dexie {
  constructor() {
    super('BavoxDB');
    this.version(1).stores({
      tasks: '++id, data, timestamp',
      deletedTasks: '++id, data, timestamp',
      pictures: '++id, data, timestamp'
    });

    this.open()
    .then(() => console.info("DB Opened"))
    .catch(err => console.log(err.message));
  }

  // offline add task
  async addTask(data: any) {
    return this.table('tasks').add({
      data: data,
      timestamp: new Date()
    });
  }

  async getPendingTasks() {
    return this.table('tasks').toArray();
  }

  async removeTask(id: number) {
    console.log('remove task from idb', id);
    
    return this.table('tasks').delete(id);
  }

  // offline delete task
  async addDeletedTask(data: any) {
    return this.table('deletedTasks').add({
      data: data,
      timestamp: new Date()
    });
  }

  async getPendingDeletedTasks() {
    return this.table('deletedTasks').toArray();
  }

  async removeDeletedTask(id: number) {
    return this.table('deletedTasks').delete(id);
  }

  // offline add picture
  async uploadPicture(data: any) {
    return this.table('pictures').add({
      data: data,
      timestamp: new Date()
    });
  }

  async getPendingPictures() {
    return this.table('pictures').toArray();
  }

  async removePicture(id: number) {
    return this.table('pictures').delete(id);
  }


}
