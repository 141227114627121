// @ts-nocheck comment
import {EventEmitter, Inject, Injectable, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { io } from "socket.io-client";

import {ResponseDto} from '../dto/ResponseDto';
import {WebSocketClientRequestDto} from '../dto/WebSocketClientRequestDto';
import {WebSocketEventDto} from '../dto/WebSocketEventDto';
import {BotatoWebSocketEvent} from '../dto/WebSocketEvent';
import {DialogEvent} from '../dto/DialogEvent';
import {BotatoChatStorageService} from './botato-chat-storage.service';
import {CommunicationService} from './communication.service';
import {TranslateService} from '@ngx-translate/core';
import {BotatoConfig} from '../botato.config';
import {BotatoUserService} from './botato-user.service';
import {BotatoChatWebSocketDataInterceptor} from './botato-chat-web-socket-data-interceptor.service';
import {WebSocketMessage} from '../dto/WebSocketMessage';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';
import { SocketService } from 'src/app/core/services/socketService/socket.service';

/* eslint-disable @typescript-eslint/no-unsafe-call*/
/* eslint-disable @typescript-eslint/no-unsafe-member-access*/
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// eslint-disable-next-line

@Injectable({
    providedIn: 'root'
})
export class BotatoChatWebSocketCommunicationService implements OnInit extends CommunicationService {
    public messageReceived: EventEmitter<ResponseDto> = new EventEmitter<ResponseDto>();
    public historyReceived: EventEmitter<(ResponseDto | string)[]> = new EventEmitter<(ResponseDto | string)[]>();
    public connectionStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
    public startTyping: EventEmitter<ResponseDto> = new EventEmitter<ResponseDto>();

    private authToken: string;
    private socket;

    constructor(
        private socketService: SocketService,
        private storageService: BotatoChatStorageService,
        private translationService: TranslateService,
        private userService: BotatoUserService,
        private dataInterceptor: BotatoChatWebSocketDataInterceptor,
    ) {
        super();
    }

    public welcome(): void {
        setTimeout(() => {
            this.send(BotatoWebSocketEvent.EVENT, new WebSocketEventDto(DialogEvent.WELCOME, this.translationService.currentLang, undefined, this.clientId, this.botId, undefined, undefined, this.authToken));
        });
    }

    public sendMessage(text: string, inputSource: InputSourceType): void {
        this.send(BotatoWebSocketEvent.MESSAGE, new WebSocketClientRequestDto(text,  undefined, this.translationService.currentLang, this.clientId, this.botId, inputSource, this.authToken));
    }

    // eslint-disable-next-line
    public sendEvent(eventName: string, inputSource: InputSourceType, eventPayload: any): void {
        this.send(BotatoWebSocketEvent.EVENT, new WebSocketEventDto(eventName, this.translationService.currentLang, undefined, this.clientId, this.botId, inputSource, eventPayload, this.authToken));
    }

    // Send Message or Event
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private send(type: string, data: WebSocketMessage): void {
        this.ensureConnection();
        this.userService.getUserId(this.botId).then(userId => {
            const botInUserId = userId.split('.');
            data.userId = (botInUserId.length === 1) ? userId : botInUserId[1];
            data = this.dataInterceptor.transform(type, data);
            this.socket.emit(type, data);
        })
            .catch(error => console.log(error));
    }

    private ensureConnection(): void {
        if (this.socket === undefined || !this.socket.connected) {
            this.setupSocket();
        }
    }

    private setupSocket(): void {
        this.socketService.connectSocket();
        this.socket = this.socketService.socket;

        this.socket.on(BotatoWebSocketEvent.MESSAGE, (data: ResponseDto) => {
            this.lastBotResult = data;
            this.messageReceived.emit(data);
        });

        this.socket.on(BotatoWebSocketEvent.EVENT, (data: WebSocketEventDto) => {
            if (data.eventKey === DialogEvent.START_TYPING) {
                this.startTyping.emit();
                return;
            }

            throw new Error(`Could not handle event '${data.eventKey}'`);
        });

        this.socket.on(BotatoWebSocketEvent.HISTORY, (data: (ResponseDto | string)[]) => {
            this.historyReceived.emit(data);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.socket.on(BotatoWebSocketEvent.CONNECT, (data: any, callback: () => void) => {
            this.connectionStateChanged.emit(true);
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.socket.on(BotatoWebSocketEvent.DISCONNECT, (data: any, callback: () => void) => {
            this.connectionStateChanged.emit(false);
        });

    }

    public setAuthToken(authToken: string): void {
        this.authToken = authToken;
    }

}
