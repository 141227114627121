<div class="min-h-screen">
  <!-- header nav -->
  <div class="flex items-center gap-4 px-4 pt-8 text-error-color-500">
    <button (click)="goBackToPrevPage()" class="-m-1 btn btn-circle btn-link btn-sm">
      <app-svg-icon
        [src]="chevronLeft"
        [stroke]="'#fb4848'"
        [height]="'24px'"
        [width]="'24px'"
        strokeWidth="2px"
      />
    </button>
    <h1 class="text-2xl font-semibold text-center">Page Not Found</h1>
  </div>

  <div class="flex flex-col items-center justify-center py-28 gap-16">
    <div class="text-center space-y-2">
      <h1 class="text-4xl font-semibold text-error-color-500">404</h1>
      <p class="text-xl font-medium text-error-color-500">Oooppsss...</p>
    </div>
    <img class="w-[173px]" src="../../../../assets/images/logo/bavox-mascot-thinking.svg" alt="">
  </div>
  <div class="flex justify-center">
    <button routerLink="/" class="btn btn-link btn-link-neutral btn-lg">Go Home</button>
  </div>
</div>