<div class="fixed w-full lg:w-[1008px] md:w-[900px] xs:w-[430px] bottom-0 !bg-white !shadow-[0px_-3px_51px_-16px_rgba(0,0,0,0.15)] rounded-t-3xl" [class.bav-is-recording]="isRecording">

    <div class="m-2 p-2 border-2 border-secondary-color-500 rounded-full" *ngIf="!liveTranscript && isRecording">
        <p class="bav-live-transcript-recording !text-secondary-color-500" *ngIf="!liveTranscript && isRecording">Ich höre zu...</p>
    </div>
    <div class="m-2 p-2 border-2 border-secondary-color-500 rounded-full" *ngIf="liveTranscript">
        <p class="bav-live-transcript !text-secondary-color-500 !p-0 !min-h-0" *ngIf="liveTranscript">{{liveTranscript}}</p>
    </div>

    <div class="bav-input-modes">

        <div class="flex justify-center items-center py-3 px-9 !relative" *ngIf="mode === 'voice'">
            <div class="!absolute !z-10 left-9">
                <label class="cursor-pointer grid place-items-center">
                <input (click)="goToTaskPage()" type="checkbox" value="synthwave" class="toggle [--tglbg:#ECECEC] bg-main-color-500 hover:bg-main-color-500 border-none toggle-lg row-start-1 col-start-1 col-span-2"/>
                
                <svg style="stroke-width: 1.5px;" class="col-start-1 row-start-1 stroke-base-100" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.25 6.875H13.75M6.25 9.375H10M1.875 10.6333C1.875 11.9667 2.81083 13.1283 4.13083 13.3225C5.07167 13.4608 6.0225 13.5667 6.98333 13.6383C7.275 13.66 7.54167 13.8133 7.70417 14.0558L10 17.5L12.2958 14.0558C12.3764 13.9361 12.4831 13.8362 12.608 13.7639C12.733 13.6915 12.8727 13.6486 13.0167 13.6383C13.971 13.5671 14.9224 13.4617 15.8692 13.3225C17.1892 13.1283 18.125 11.9675 18.125 10.6325V5.6175C18.125 4.2825 17.1892 3.12167 15.8692 2.9275C13.9258 2.64226 11.9642 2.49938 10 2.5C8.00667 2.5 6.04667 2.64584 4.13083 2.9275C2.81083 3.12167 1.875 4.28334 1.875 5.6175V10.6325V10.6333Z" stroke="#F9F9F9" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                    
                <svg style="stroke-width: 1.5px;" class="col-start-2 row-start-1 stroke-base-100" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.45833 3.19667C9.40417 3.37167 9.375 3.5575 9.375 3.75C9.375 4.095 9.655 4.375 10 4.375H13.75C13.9158 4.375 14.0747 4.30915 14.1919 4.19194C14.3092 4.07473 14.375 3.91576 14.375 3.75C14.3751 3.56243 14.347 3.3759 14.2917 3.19667M9.45833 3.19667C9.57669 2.81392 9.81448 2.47911 10.1369 2.24128C10.4593 2.00345 10.8494 1.87509 11.25 1.875H12.5C13.3433 1.875 14.0558 2.43167 14.2917 3.19667M9.45833 3.19667C9.145 3.21583 8.83333 3.23833 8.52167 3.26333C7.57917 3.34167 6.875 4.14417 6.875 5.09V6.875M14.2917 3.19667C14.605 3.21583 14.9167 3.23833 15.2283 3.26333C16.1708 3.34167 16.875 4.14417 16.875 5.09V13.75C16.875 14.2473 16.6775 14.7242 16.3258 15.0758C15.9742 15.4275 15.4973 15.625 15 15.625H13.125M6.875 6.875H4.0625C3.545 6.875 3.125 7.295 3.125 7.8125V17.1875C3.125 17.705 3.545 18.125 4.0625 18.125H12.1875C12.705 18.125 13.125 17.705 13.125 17.1875V15.625M6.875 6.875H12.1875C12.705 6.875 13.125 7.295 13.125 7.8125V15.625M6.25 13.125L7.5 14.375L10 11.25" stroke="#F9F9F9" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                    
                </label>                  
            </div>
            <div *ngIf="notSupported" class="dropdown dropdown-top">
                <div tabindex="0" class="border-2 border-secondary-color-500 rounded-full p-[2px] border-opacity-50 cursor-not-allowed">
                    <button
                        class="btn btn-circle btn-secondary disabled:!bg-opacity-50"
                        type="button"
                        [disabled]="notSupported"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 18.75C13.5913 18.75 15.1174 18.1179 16.2426 16.9926C17.3679 15.8674 18 14.3413 18 12.75V11.25M12 18.75C10.4087 18.75 8.88258 18.1179 7.75736 16.9926C6.63214 15.8674 6 14.3413 6 12.75V11.25M12 18.75V22.5M8.25 22.5H15.75M12 15.75C11.2044 15.75 10.4413 15.4339 9.87868 14.8713C9.31607 14.3087 9 13.5456 9 12.75V4.5C9 3.70435 9.31607 2.94129 9.87868 2.37868C10.4413 1.81607 11.2044 1.5 12 1.5C12.7956 1.5 13.5587 1.81607 14.1213 2.37868C14.6839 2.94129 15 3.70435 15 4.5V12.75C15 13.5456 14.6839 14.3087 14.1213 14.8713C13.5587 15.4339 12.7956 15.75 12 15.75Z" stroke="#F9F9F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                        
                    </button>  
                </div>
                <div tabindex="0" class="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box w-64 mb-5 -ml-[100px]">
                  <div tabindex="0" class="card-body">
                    <h2 class="card-title">Nicht unterstützt!</h2> 
                    <p>Dieser Browser unterstützt keine Spracherkennung</p>
                    <p>Bitte öffnen Sie es in einem anderen Browser</p>
                  </div>
                </div>
            </div>
            <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white" data-tip="Sprachaufnahme">
                <div *ngIf="!notSupported" class=" border-2 border-secondary-color-500 rounded-full p-[2px]">
                    <button
                        class="btn btn-circle btn-secondary"
                        [class.bav-is-recording]="isRecording" type="button"
                        [disabled]="!isConnected"
                        (mousedown)="startRecording()"
                        (mouseup)="stopRecording()"
                        (mouseleave)="stopRecording()"
                        (touchstart)="onTouchStart($event)"
                        (touchend)="onTouchEnd($event)"
                        (touchcancel)="onTouchCancel($event)"
                        (touchmove)="$event.preventDefault()"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 18.75C13.5913 18.75 15.1174 18.1179 16.2426 16.9926C17.3679 15.8674 18 14.3413 18 12.75V11.25M12 18.75C10.4087 18.75 8.88258 18.1179 7.75736 16.9926C6.63214 15.8674 6 14.3413 6 12.75V11.25M12 18.75V22.5M8.25 22.5H15.75M12 15.75C11.2044 15.75 10.4413 15.4339 9.87868 14.8713C9.31607 14.3087 9 13.5456 9 12.75V4.5C9 3.70435 9.31607 2.94129 9.87868 2.37868C10.4413 1.81607 11.2044 1.5 12 1.5C12.7956 1.5 13.5587 1.81607 14.1213 2.37868C14.6839 2.94129 15 3.70435 15 4.5V12.75C15 13.5456 14.6839 14.3087 14.1213 14.8713C13.5587 15.4339 12.7956 15.75 12 15.75Z" stroke="#F9F9F9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>                        
                    </button>                  
                </div>
            </span>
            <span class="md:tooltip md:tooltip-primary tooltip-top before:!text-white !absolute !z-10 right-9" data-tip="Text eingeben">
                <button (click)="toggleMode()" class="flex items-center justify-center">
                    <app-svg-icon [src]="keyboard" fill="black"/>
                </button>
            </span>
        </div>

        <div class="flex justify-between items-center py-3 px-9" *ngIf="mode === 'text'">
            <div tabindex="0" *ngIf="notSupported" class="dropdown dropdown-top dropdown-right">
                <div class="h-full flex items-center">
                    <button tabindex="0" *ngIf="notSupported" class="btn btn-circle btn-link btn-sm disabled:!bg-transparent disabled:opacity-50" [disabled]="notSupported">
                        <app-svg-icon
                            [src]="chevronLeft"
                            height="32px"
                            width="32px"
                            strokeWidth="2px"
                        />
                    </button>
                </div>

                <div tabindex="0" class="card compact dropdown-content z-[1] shadow bg-base-100 rounded-box w-64 mb-5 -ml-10">
                  <div tabindex="0" class="card-body">
                    <h2 class="card-title">Nicht unterstützt!</h2> 
                    <p>Dieser Browser unterstützt keine Spracherkennung</p>
                    <p>Bitte öffnen Sie es in einem anderen Browser</p>
                  </div>
                </div>
            </div>
            <button *ngIf="!notSupported" class="btn btn-circle btn-link btn-sm" (click)="toggleMode()">
                <app-svg-icon
                    [src]="chevronLeft"
                    height="32px"
                    width="32px"
                    strokeWidth="2px"
                />
            </button>
            <div
                contenteditable="true" class="border-2 rounded-3xl flex-1 border-secondary-color-500 mx-3 p-3 mt-[2px] break-all"
                [(contenteditableModel)]="userText"
                (keydown)="onKeyDown($event)"
                [attr.placeholder]="'Text eingeben...'"
            >                    
            </div>
            <button class="btn btn-circle btn-outline" type="button" (click)="onSendButtonClicked()"
                    [disabled]="!canSendMessage()">
                <app-svg-icon
                    [src]="chevronRight"
                    [stroke]="canSendMessage() ? '#001A30' : '#EAEAEA'"
                    height="32px"
                    width="32px"
                    strokeWidth="2px"
                />
            </button>
        </div>
    </div>
</div>
