import {ApplicationRef, Component, OnDestroy, OnInit} from '@angular/core';
import {BavoxVoiceInputOptions} from './bavox-voice-input.options';
import {Subscription} from 'rxjs';
import {VoiceRecognitionService} from './voice-recognition/voice-recognition.service';
import {GoogleCloudVoiceRecognitionService} from './voice-recognition/google-cloud-voice-recognition.service';
import {WebSpeechVoiceRecognitionService} from './voice-recognition/web-speech-voice-recognition.service';
import {TranslateService} from '@ngx-translate/core';
import {InputComponent} from '../../../libs/botato-angular-lib/botato-chat-input/input-type/input.component';
import {DialogViewModelCreatorService} from '../../../libs/botato-angular-lib/service/dialog-view-model-creator.service';
import {CommunicationService} from '../../../libs/botato-angular-lib/service/communication.service';
import { icons } from 'src/assets/images/icons';
import { Location } from '@angular/common';
import { ActivatedRoute, Route, Router } from '@angular/router';


@Component({
    templateUrl: './bavox-voice-input.component.html',
    styleUrls: ['./bavox-voice-input.component.scss']
})
export class BavoxVoiceInputComponent extends InputComponent<BavoxVoiceInputOptions> implements OnInit, OnDestroy {
    public keyboard: string = icons.keyboard;
    public chevronRight: string = icons.chevronRight;
    public chevronLeft: string = icons.chevronLeft;

    public static TYPE: string = 'bavoxVoice';

    private liveTranscriptSubscription: Subscription = new Subscription;
    private isRecordingSubscription: Subscription = new Subscription;
    private isConnectedSubscription: Subscription = new Subscription;
    private finalTextSubscription: Subscription = new Subscription;
    public liveTranscript!: string;
    public isRecording: boolean = false;
    public mode: 'voice' | 'text' = 'voice';
    public userText!: string;
    public isConnected: boolean = true;
    public notSupported: boolean | undefined = false;
    private voiceService!: VoiceRecognitionService;

    constructor(
        public dialogViewModelCreator: DialogViewModelCreatorService,
        private applicationRef: ApplicationRef,
        private translationService: TranslateService,
        private communicationService: CommunicationService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super();

        if (BavoxVoiceInputComponent.isiOS()) {
            this.voiceService = new GoogleCloudVoiceRecognitionService();
            // console.log('iOS => Server');
        } else {
            this.voiceService = new WebSpeechVoiceRecognitionService(applicationRef, translationService);
            if(this.voiceService.notSupported === true) {
                this.notSupported = true;
                this.mode = 'text';
            } else {
                this.notSupported = false;
            }
            // console.log('NON iOS => Web Speech API');
        }
    }

    public toggleMode(): void {
        this.mode = this.mode === 'voice' ? 'text' : 'voice';
    }

    public onSendButtonClicked(): void {
        this.sendText(this.userText);
        this.userText = '';
        this.applicationRef.tick();
    }

    private sendText(textToSend: string): void {
        this.dialogViewModelCreator.addUserTextMessage(textToSend);
        this.communicationService.sendMessage(textToSend, 'text');
    }

    public onTouchStart(event: TouchEvent): void {
        this.startRecording();
        event.preventDefault();
    }

    public onTouchEnd(event: TouchEvent): void {
        this.stopRecording();
        event.preventDefault();
    }

    public onTouchCancel(event: TouchEvent): void {
        this.stopRecording();
        event.preventDefault();
    }

    public onKeyDown(event: KeyboardEvent): void {
        const enterKeyCode: number = 13;
        if (event.keyCode === enterKeyCode || event.key === 'Enter') {
            event.preventDefault();

            // if there is a very fast typer it can happen that the keyDown Event of the Enter-key does happen before the keyUp-event of the last
            // key the user typed before hitting the enter button. In this case the binding did not yet refresh
            // the userText and we send an incomplete message. To fix this we add additional 100ms to make sure
            // the event of the last keystroke happened.
            setTimeout(() => {
                if (this.canSendMessage()) {
                    this.onSendButtonClicked();
                }
            }, 100);
        }
        this.applicationRef.tick();
    }

    public canSendMessage(): boolean {
        return this.userText !== undefined && this.userText.trim().length !== 0 && this.isConnected !== false;
    }

    public startRecording(): void {
        if (this.isConnected === true && this.voiceService != undefined) {
            this.voiceService.start();
        }
    }

    public stopRecording(): void {
        this.voiceService.stop();
    }

    public ngOnInit(): void {
        this.liveTranscriptSubscription = this.voiceService.liveTranscript$.subscribe(liveTranscript => this.liveTranscript = liveTranscript);
        this.isRecordingSubscription = this.voiceService.isRecording$.subscribe(isRecording => this.isRecording = isRecording);
        this.isConnectedSubscription = this.communicationService.connectionStateChanged.subscribe(isConnected => this.isConnected = isConnected);
        this.finalTextSubscription = this.voiceService.finalText$.subscribe(finalText => this.sendText(finalText))
    }

    public ngOnDestroy(): void {
        this.liveTranscriptSubscription.unsubscribe();
        this.isRecordingSubscription.unsubscribe();
        this.isConnectedSubscription.unsubscribe();
        this.finalTextSubscription.unsubscribe();
    }

    private static isiOS() {
        return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }
    public goToTaskPage(): void {
        this.route.params.subscribe(params => {
            const { projectId, journalId, mode } = params;
            this.router.navigate(['/tasks', projectId, journalId, mode]);
        })
    };
}
