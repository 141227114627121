// @ts-nocheck comment
import {DeviceInfoDto} from './DeviceInfoDto';
import {WebSocketMessage} from './WebSocketMessage';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';

export class WebSocketEventDto  extends WebSocketMessage{

    public userId: string;

    constructor(
        public eventKey: string,
        language: string,
        deviceInfo: DeviceInfoDto,
        clientId: string,
        botId: string,
        inputSource?: InputSourceType,
        // Data can be any
        // eslint-disable-next-line
        public data?: any,
        authToken?: string
    ) {
        super(language, clientId, botId, {deviceInfo: deviceInfo}, inputSource, authToken);
    }
}
