import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(private http: HttpClient, public resources: { prefix: string, suffix: string }[] = []) {}

  public getTranslation(lang: string): Observable<any> {
    const observables: Observable<any>[] = this.resources.map(resource => {
      const path = `${resource.prefix}${lang}${resource.suffix}`;
      return this.http.get(path);
    });

    return forkJoin(observables).pipe(map(response => {
      return response.reduce((acc, cur) => {
        return { ...acc, ...cur };
      }, {});
    }));
  }
}
