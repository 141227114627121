<div class="dropdown w-full input-wrapper">
  <div
    tabindex="0"
    role="button"
    class="select select-bordered focus:border-secondary-color-500 w-full text-base font-medium rounded-full justify-start items-center"
    [ngClass]="{
      'text-secondary-color-500': (selectedItem !== null || control.value !== null),
      'text-white-shades-400': (selectedItem === null && (control.value === null || control.value === ''))
    }"
    [attr.aria-expanded]="isFocused"
  >
    {{ control.value ? controlValueItemName(control.value) : selectedItem?.itemName ?? placeholder}}
</div>
  <div *ngIf="items !== null" class="dropdown-content menu mt-2 p-2 w-full !block z-50 border-[1px] border-white-shades-400 bg-white rounded-box">
    <ul tabindex="0" class="overflow-auto space-y-1">
      <li
        class="hover:bg-white-shades-200 rounded-lg"
        [ngClass]="{'bg-white-shades-200': item.itemName === selectedItem?.itemName}"
        *ngFor="let item of items"
        (click)="selectItem(item)"
      >
        <a>{{item.itemName}}</a>
      </li>
    </ul>
  </div>
  <select #hiddenSelect [formControl]="control" class="hidden">
    <option *ngFor="let item of items" hidden id="{{ item.itemValue }}" [value]="item">{{item.itemName}}</option>
  </select>
  <p
    class="input-error-message"
    *ngIf="requiredText && (control?.dirty || control?.touched) && control?.errors?.['required']"
  >
    {{ requiredText }}
  </p>
</div>