// @ts-nocheck comment
import {
    ApplicationRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {ResponseDto} from '../dto/ResponseDto';
import {QuickReplyDto} from '../dto/QuickReplyDto';
import {DialogViewModelCreatorService} from '../service/dialog-view-model-creator.service';
import {DialogViewModel} from '../viewmodel/DialogViewModel';
import {CommunicationService} from '../service/communication.service';
import {BotatoChatManagerService} from '../service/botato-chat-manager.service';
import {MessageGroupViewModel} from '../viewmodel/MessageGroupViewModel';
import {Subscription} from 'rxjs';
import {DialogConfigInputOptionType, DialogConfigInputType} from '../../botato-botengine-browser/Core/DialogConfig/DialogConfig';
import {InputSourceType} from '../../botato-botengine-browser/InstantMessagingAdapter/WebSocket/dto/InputSourceType';

@Component({
    selector: 'botato-chat',
    templateUrl: './botato-chat.component.html',
    encapsulation: ViewEncapsulation.None
})
export class BotatoChatComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild('dialog', {static: true}) private dialogDiv: ElementRef;

    public isConnected: boolean;
    public dialogViewModel: DialogViewModel = new DialogViewModel();
    public avatarURL: boolean;
    public indicatorAvatar: string;

    // TODO how to implement this feature and why is this needed? -> Noora!
    @Input()
    public inputFocusable: boolean = true;

    @Input()
    public inputComponentFocus: boolean;

    @Input()
    public initialInputType: DialogConfigInputType = 'text';

    @Input()
    public clientId: string;

    @Input()
    public botId: string;

    @Input()
    public initialInputOptions: DialogConfigInputOptionType;

    @Output()
    public onQuickReplyClick = new EventEmitter<QuickReplyDto>();

    private subscription = new Subscription();

    constructor(private communicationService: CommunicationService,
                public dialogViewModelCreator: DialogViewModelCreatorService,
                private chatManagerService: BotatoChatManagerService,
                private applicationRef: ApplicationRef) {

        // Initial tick to display data and run ngOnInit
        setTimeout(() => {
            applicationRef.tick();
        });
    }

    public ngOnInit(): void {
        if (this.botId !== undefined) {
            this.communicationService.botId = this.botId;
        }

        this.dialogViewModelCreator.getDialogViewModelObservable().subscribe((dialogViewModel) => {
            this.dialogViewModel = dialogViewModel;
            this.scrollToBottom();
        });

        this.subscription.add(this.chatManagerService.getScrollToBottomSubject().subscribe(() => {
            this.scrollToBottom();
        }));

        this.subscription.add(this.communicationService.messageReceived.subscribe((botResult: ResponseDto) => {
            this.communicationService.lastBotResult = botResult;
            this.dialogViewModelCreator.addBotMessage(botResult);
            this.applicationRef.tick();
            this.scrollToBottom();
        }));

        this.subscription.add(this.communicationService.startTyping.subscribe(() => {
            this.dialogViewModelCreator.showTypingIndicator();
            this.applicationRef.tick();
            this.scrollToBottom();
        }));

        this.subscription.add(this.communicationService.connectionStateChanged.subscribe((state: boolean) => {
            this.isConnected = state;
            this.applicationRef.tick();
        }));

        this.subscription.add(this.communicationService.historyReceived.subscribe((history: (ResponseDto | string)[]) => {
            this.dialogViewModelCreator.addHistory(history);
            this.applicationRef.tick();
            this.scrollToBottom();
        }));
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public ngOnChanges(changes: { [propertyName: string]: SimpleChange }): void {
        if (changes['clientId'] && this.clientId) {
            this.communicationService.clientId = this.clientId;
        }

        // TODO: change botId
        if (changes['botId'] && this.botId) {
            this.communicationService.botId = this.botId;
        }
    }

    public selectQuickReply(quickReply: QuickReplyDto): void {
        const message = quickReply.sendText !== undefined ? quickReply.sendText : quickReply.displayText;
        this.onQuickReplyClick.emit(quickReply);
        this.sendMessage(message, 'quickreply');
    }

    public selectItem(message: string): void {
        this.sendMessage(message, 'selectionlist');
    }

    public botAvatar(messageGroup: MessageGroupViewModel): boolean {
        let messageNumeration;
        if (this.dialogViewModel.messageGroups[0].sender === 'client') {
            messageNumeration = 4;
        } else {
            messageNumeration = 1;
        }
        let status: boolean = false;
        if (this.dialogViewModel.messageGroups.indexOf(messageGroup) > messageNumeration) {
            if (this.dialogViewModel.messageGroups[this.dialogViewModel.messageGroups.indexOf(messageGroup) - 2].botAvatarImageUrl !== undefined) {
                status = true;
            }
        }
        return status;
    }

    public get inputType(): string {
        if (this.communicationService.lastBotResult === undefined) {
            return this.initialInputType;
        } else {
            if (this.communicationService.lastBotResult.additionalData !== undefined
                && this.communicationService.lastBotResult.additionalData.promptOptions.input !== undefined
                && this.communicationService.lastBotResult.additionalData.promptOptions.input.type !== undefined) {
                return this.communicationService.lastBotResult.additionalData.promptOptions.input.type;
            } else {
                return this.initialInputType;
            }
        }
    }

    public get inputOptions(): DialogConfigInputOptionType {
        if (this.communicationService.lastBotResult === undefined) {
            return this.initialInputOptions;
        } else {
            if (this.communicationService.lastBotResult.additionalData !== undefined
                && this.communicationService.lastBotResult.additionalData.promptOptions.input !== undefined
                && this.communicationService.lastBotResult.additionalData.promptOptions.input.options !== undefined) {
                return this.communicationService.lastBotResult.additionalData.promptOptions.input.options;
            } else {
                return this.initialInputOptions;
            }
        }
    }

    private sendMessage(text: string, inputSource: InputSourceType): void {
        this.dialogViewModelCreator.addUserTextMessage(text);
        this.communicationService.sendMessage(text, inputSource);
    }

    private scrollToBottom(): void {
        setTimeout(() => {
            // eslint-disable-next-line
            this.dialogDiv.nativeElement.scrollTop = this.dialogDiv.nativeElement.scrollHeight;
        }, 10);
    }


}
