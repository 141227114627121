import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortBy',
  pure: false
})
export class SortByPipe implements PipeTransform {

  transform(tasks: any[], sortBy: string | null, sortOrder: string | null): any[] {
    if (!tasks || !sortBy || !sortOrder) return tasks;

    const sortFunction = (a: any, b: any): number => {
      const dateA = a[sortBy] ? new Date(a[sortBy]).getTime() : 0;
      const dateB = b[sortBy] ? new Date(b[sortBy]).getTime() : 0;

      if (sortOrder === 'asc') {
        return dateA - dateB;
      } else { // for 'desc'
        return dateB - dateA;
      }
    };

    return [...tasks].sort(sortFunction);
  }
}
