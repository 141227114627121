import {BavoxVoiceInputComponent} from './voice-input-component/bavox-voice-input.component';
import {Injectable} from '@angular/core';
import {BotatoChatInputResolverService} from '../../libs/botato-angular-lib/botato-chat-input/botato-chat-input-resolver.service';

@Injectable({providedIn: 'root'})
export class BavoxChatInputResolverService extends BotatoChatInputResolverService {
    constructor() {
        super();
        this.inputMappings.set(BavoxVoiceInputComponent.TYPE, BavoxVoiceInputComponent);
    }
}
