// @ts-nocheck comment
// eslint-disable-next-line @typescript-eslint/no-explicit-any 
declare const gapi: any;

export class GoogleApiClient {

    public static load(): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        gapi.load('client', GoogleApiClient.loadClient);
    }

    private static loadClient(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        gapi.client.setApiKey('AIzaSyBvm7w3TvshLY3qQu_vUL6F33jWsRwFjV4');
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        return gapi.client.load('https://content.googleapis.com/discovery/v1/apis/speech/v1/rest');
    }

    public static async recognizeSpeech(base64AudioString: string): Promise<string> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
        const result = await gapi.client.speech.speech.recognize({
            'resource': {
                'audio': {
                    'content': base64AudioString
                },
                'config': {
                    'enableAutomaticPunctuation': true,
                    'encoding': 'LINEAR16',
                    'languageCode': 'de-CH',
                    'speechContexts': [{
                        'phrases': ['Das ist ein Gurkensalat']
                    }]
                }
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
        if (result.result.results !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
            return result.result.results[0].alternatives[0].transcript;
        }

        return undefined;
    }
}
