import {ActivatedRoute} from '@angular/router';
import {Injectable} from '@angular/core';
import {BotatoChatWebSocketDataInterceptor} from '../../libs/botato-angular-lib/service/botato-chat-web-socket-data-interceptor.service';
import {WebSocketMessage} from '../../libs/botato-angular-lib/dto/WebSocketMessage';

@Injectable()
export class BavoxWebsocketInterceptor extends BotatoChatWebSocketDataInterceptor {

    constructor(private route: ActivatedRoute) {
        super();
    }

    public override transform(type: string, input: WebSocketMessage): WebSocketMessage {
        const projectId = this.route.children[0].snapshot.params['projectId'] as string;
        const journalId = this.route.children[0].snapshot.params['journalId'] as string;
        const journalType = this.route.children[0].snapshot.params['mode'] as string;
        if (journalId === undefined) {
            throw new Error('Could not find a JournalId - Did we change the name of the parameter in the url?');
        }

        input.additionalData['projectId'] = projectId;
        input.additionalData['journalId'] = journalId;
        input.additionalData['journalType'] = journalType;
        return input;
    }

}
