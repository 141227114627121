import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
})
export class SvgIconComponent {
  @Input() src?: string;
  @Input() classes?: string;

  @Input() stroke?: string;
  @Input() strokeWidth?: string;
  @Input() fill?: string = 'none';
  @Input() height?: string;
  @Input() width?: string;

  public sanitizedSrc?: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.updateIcon();
  }

  ngOnChanges() {
    this.updateIcon();
  }

  updateIcon(src = this.src) {
    if (src) {
      const regexStroke = /stroke="([^"]*)"/g;
      const regexStrokeWidth = /stroke-width="([^"]*)"/g;
      const regexFill = /fill="([^"]*)"/g;
      const regexStyle = /<svg/g;

      if(this.strokeWidth){
        src = src.replace(regexStrokeWidth, `stroke-width="${this.strokeWidth}"`); 
      }
      if(this.stroke){
        src = src.replace(regexStroke, `stroke="${this.stroke}"`);
      }
      if(this.fill){
        src = src.replace(regexFill, `fill="${this.fill}"`);
      }
      if(this.height && this.width){
        src = src.replace(regexStyle, `<svg style="width:${this.width}; height:${this.height}"`);
      }
    }
    this.sanitizedSrc = this.sanitizer.bypassSecurityTrustHtml(src!);
  }
  
}
